export const themes = [
  {//landing default color, all white
    //theme 0
    fontFamily: "",
    sidebarWidth: 100,
    headerHeight: 40,
    maxPax: 30,
    sidebarFixedSize: false,
    headerLogoPosition: "center",
    primaryColor: "white",
    checkinPrimaryColor: "white",
    secondaryColor: "white",
    selectedColor: "white",
    //menu header color
    menuHeaderColor: "white",
    menuTitleColor: "white",
    optionHeaderBackgroundColor: "white",
    optionHeaderColor: "white",
    optionBorderColor: "white",
    optionPriceColor: "white",
    backgroundColor: "white",
    cartColor1: "white", //check out background
    cartColor2: "white",
    cartFont: "white",
    borderColor: "white",
    topBarSubBg: "wghite",
    fontPrimary: "white",
    fontSecondary: "white",
    fontShadow: "white",
    fontPrice: "white",
    fontAlert: "red",
    selectedCategory: "white",
    unselectedCategory: "white",
    fontItemPrice: 'white',
    headerColor: "white",
    itemPrice: "white",
    selectedCatBg: "white",
    selectedSubCatBg: "white"
  },
  { //Black Theme
    //theme 0
    fontFamily: "\'RobotoCondensed\'",
    sidebarWidth: 100,
    headerHeight: 40,
    maxPax: 30,
    sidebarFixedSize: true,

    headerLogoPosition: "center",
    topBarSubBg: "black",
    primaryColor: "black",
    secondaryColor: "#1e1e1e", //side bar background
    // checkinPrimaryColor: "#1e1e1e",

    selectedColor: "#Cf000d",

    menuHeaderColor: "#1e1e1e", //menu top background
    menuTitleColor: "black",

    optionHeaderBackgroundColor: "#1e1e1e",  //"#455a64",
    optionHeaderColor: "#f8dd78", //"#f8dd78",
    optionBorderColor: "#ddd",
    optionPriceColor: "white",

    backgroundColor: "#fff",

    cartColor1: "#f3962b",
    cartColor2: "#3fbd11",
    cartFont: "white",

    borderColor: "#ddd",

    fontPrimary: "black", //container detail font color
    fontSecondary: "#f8dd78", //menu item color
    headerColor: "#f8dd78",
    fontShadow: "black",
    fontPrice: "#cf000d",
    fontAlert: "red",
    selectedCategory: "white",//category selected color
    unselectedCategory: "#f8dd78", //category color
    fontItemPrice: '#f8dd78',
    checkOutArrow: "#f8dd78", //checkOut Arrow Color
    itemPrice: "white",
    selectedCatBg: "#f3962b",
    selectedSubCatBg: "#f3962b"
  },
  { //theme red for restaurant Ippudo
    //theme 1
    fontFamily: "\'Roboto\'",
    sidebarWidth: 105,
    headerHeight: 40,
    maxPax: 30,
    sidebarFixedSize: true,
    headerLogoPosition: "center",
    topBarSubBg: "#CF000d",
    primaryColor: "#Cf000d",
    secondaryColor: "black",
    checkinPrimaryColor: "#CF000D",
    selectedColor: "#Cf000d",
    menuHeaderColor: "#CF000D",
    menuTitleColor: "black",
    optionHeaderBackgroundColor: "white",
    optionHeaderColor: "black",
    optionBorderColor: "#ddd",
    optionPriceColor: "#CF000D",
    backgroundColor: "#fff",
    cartColor1: "#CF000D",
    cartColor2: "#004D4A",
    cartFont: "white",

    borderColor: "#ddd",

    fontPrimary: "black",
    fontSecondary: 'black',
    headerColor: "black",
    fontShadow: "black",
    fontPrice: "#cf000d",
    fontAlert: "red",
    selectedCategory: "#Cf000d",
    unselectedCategory: "white",
    fontItemPrice: "#Cf000d",
    checkOutArrow: "white", //checkOut Arrow Color
    itemPrice: "white",
    selectedCatBg: "#CF000D",
    selectedSubCatBg: "black"
  },
  { //monica theme, Purple
    //theme 2
    fontFamily: "\'RobotoCondensed\'",
    sidebarWidth: 100,
    headerHeight: 40,
    maxPax: 30,
    sidebarFixedSize: false,
    headerLogoPosition: "center",
    topBarSubBg: "#6A5ACD",
    primaryColor: "#6A5ACD",
    secondaryColor: "white",
    // checkinPrimaryColor: "#1e1e1e",
    selectedColor: "#Cf000d",
    fontPrimary: "black",
    fontSecondary: "black",
    headerColor: "black",
    fontPrice: "#6A5ACD",
    //menu header color
    menuHeaderColor: "#b6b6ff",
    menuTitleColor: "white",

    optionHeaderBackgroundColor: "white",
    optionHeaderColor: "white",
    optionBorderColor: "#ddd",
    optionPriceColor: "white",

    backgroundColor: "white",
    cartFont: "white",

    borderColor: "#ddd",
    cartColor1: "#6A5ACD",
    cartColor2: "#004D4A",
    fontShadow: "white",
    fontAlert: "red",
    selectedCategory: "#6A5ACD",
    unselectedCategory: "b6b6ff",
    fontItemPrice: 'white',
    checkOutArrow: "#6A5ACD", //checkOut Arrow Color
    itemPrice: "white",
    selectedCatBg: "#6A5ACD",
    selectedSubCatBg: "#004D4A"
  },
  { //Pink Theme
    //theme 3
    fontFamily: "\'RobotoCondensed\'",
    sidebarWidth: 100,
    headerHeight: 40,
    maxPax: 30,
    sidebarFixedSize: true,

    headerLogoPosition: "center",
    topBarSubBg: "#e91e63",
    primaryColor: "#e91e63",
    secondaryColor: "#e91e63",
    checkinPrimaryColor: "#e91e63",

    selectedColor: "#Cf000d",

    menuHeaderColor: "#f9878e",
    menuTitleColor: "black",

    optionHeaderBackgroundColor: "#e91e63",  //"",
    optionHeaderColor: "white", //"#f8dd78",
    optionBorderColor: "#ddd",
    optionPriceColor: "white",

    backgroundColor: "#fff",

    cartColor1: "#673ab7",
    cartColor2: "#f44336",
    cartFont: "white",

    borderColor: "#ddd",

    fontPrimary: "black",
    fontSecondary: "white",
    headerColor: "white",
    fontShadow: "black",
    fontPrice: "#cf000d",
    fontAlert: "red",
    selectedCategory: "#f8dd78",
    unselectedCategory: "white",
    fontItemPrice: 'white',
    checkOutArrow: "white", //checkOut Arrow Color
    itemPrice: "white",
    selectedCatBg: "#673ab7",
    selectedSubCatBg: "#673ab7"
  },
  { //blue theme 1
    //theme 4
    fontFamily: "\'RobotoCondensed\'",
    sidebarWidth: 100,
    headerHeight: 40,
    maxPax: 30,
    sidebarFixedSize: true,

    headerLogoPosition: "center",
    topBarSubBg: "#023047",
    //main color
    primaryColor: "#023047",
    secondaryColor: "#023047",
    checkinPrimaryColor: "#023047",

    //item option selected color
    selectedColor: "#Cf000d",
    //menu header color
    menuHeaderColor: "#023047",
    menuTitleColor: "black",

    //item option header
    optionHeaderBackgroundColor: "#023047",  //"#455a64",
    optionHeaderColor: "white", //"#f8dd78",
    optionBorderColor: "#ddd",
    optionPriceColor: "white",

    //menu background color
    backgroundColor: "white",
    //checkout button
    cartColor1: "#fb8500",
    cartColor2: "#ffb703",
    cartFont: "white",

    borderColor: "#ddd",

    fontPrimary: "#023047",
    fontSecondary: "white",
    headerColor: "white",
    fontShadow: "black",
    fontPrice: "#cf000d",
    fontAlert: "red",
    selectedCategory: "#f8dd78",
    unselectedCategory: "white",
    fontItemPrice: 'white',
    checkOutArrow: "white", //checkOut Arrow Color
    itemPrice: "white",
    selectedCatBg: "#fb8500",
    selectedSubCatBg: "#fb8500"
  },
  { //blue theme 2
    //theme 5
    fontFamily: "\'RobotoCondensed\'",
    sidebarWidth: 100,
    headerHeight: 40,
    maxPax: 30,
    sidebarFixedSize: true,

    headerLogoPosition: "center",
    topBarSubBg: "#003459",
    //main color
    primaryColor: "#003459",
    secondaryColor: "#003459",
    checkinPrimaryColor: "#003459",

    //item option selected color
    selectedColor: "#Cf000d",
    //menu header color
    menuHeaderColor: "#003459",
    menuTitleColor: "black",

    //item option header
    optionHeaderBackgroundColor: "#003459",  //"#455a64",
    optionHeaderColor: "white", //"#f8dd78",
    optionBorderColor: "#ddd",
    optionPriceColor: "white",

    //menu background color
    backgroundColor: "white",
    //checkout button
    cartColor1: "#007ea7",
    cartColor2: "#007ea7",
    cartFont: "white",

    borderColor: "#ddd",

    fontPrimary: "black",
    fontSecondary: "white",
    headerColor: "white",
    fontShadow: "black",
    fontPrice: "#cf000d",
    fontAlert: "red",
    selectedCategory: "#f8dd78",
    unselectedCategory: "white",
    fontItemPrice: 'white',
    checkOutArrow: "white", //checkOut Arrow Color
    itemPrice: "white",
    selectedCatBg: "#007ea7",
    selectedSubCatBg: "#007ea7"
  },
  {//monica new Theme, yellow theme
    //theme 6
    fontFamily: "\'RobotoCondensed\'",
    sidebarWidth: 100,
    headerHeight: 40,
    maxPax: 30,
    sidebarFixedSize: true,
    headerLogoPosition: "center",
    topBarSubBg: "#EAB23B",
    primaryColor: "#EAB23B",
    secondaryColor: "#fad17d",
    selectedColor: "#Cf000d",

    fontPrimary: "#EAB23B",
    fontSecondary: "#303133",
    headerColor: "#303133",
    fontPrice: "#cf000d",
    cartColor1: "#007ea7",
    cartColor2: "#007ea7",
    backgroundColor: "#fff",
    borderColor: "#ddd",
    cartFont: "white",
    checkOutArrow: "#f8dd78", //checkOut Arrow Color
    itemPrice: "white",
    selectedCatBg: "#007ea7",
    selectedSubCatBg: "#007ea7"
  },
  {//green theme
    //theme 7
    fontFamily: "\'RobotoCondensed\'",
    sidebarWidth: 100,
    headerHeight: 40,
    maxPax: 30,
    sidebarFixedSize: true,
    headerLogoPosition: "center",
    menuHeaderColor: "#F5F5F5",
    topBarSubBg: "#90A58A",
    primaryColor: "#90A58A",
    secondaryColor: "#90A58A", //category backgroun dcolor
    selectedColor: "#Cf000d",

    fontPrimary: "#90A58A",
    fontSecondary: "#303133",
    headerColor: "#303133",
    fontPrice: "#cf000d",

    backgroundColor: "#fff",
    borderColor: "#ddd",
    cartFont: "white",
    checkOutArrow: "#f8dd78", //checkOut Arrow Color

    selectedCategory: "white",//category selected color
    unselectedCategory: "#fff9c4", //category color

    cartColor1: "#558b2f", //check out background
    cartColor2: "#ffbb2a",
    itemPrice: "white",
    selectedCatBg: "#558b2f",
    selectedSubCatBg: "#558b2f"
  },
  {//green theme 2
    //theme 8
    fontFamily: "\'RobotoCondensed\'",
    sidebarWidth: 100,
    headerHeight: 40,
    maxPax: 30,
    sidebarFixedSize: true,
    headerLogoPosition: "center",
    menuHeaderColor: "#F5F5F5",
    optionHeaderBackgroundColor: "#90A58A",
    primaryColor: "#90A58A",
    secondaryColor: "#90A58A", //category backgroun dcolor
    selectedColor: "#Cf000d",
    topBarSubBg: "#90A58A",
    fontPrimary: "#90A58A",
    fontSecondary: "#f8dd78",
    headerColor: "black",
    fontPrice: "#cf000d",

    backgroundColor: "#fff",
    borderColor: "#ddd",
    cartFont: "white",
    checkOutArrow: "#f8dd78", //checkOut Arrow Color

    selectedCategory: "white",//category selected color
    unselectedCategory: "#fff9c4", //category color

    cartColor1: "#558b2f", //check out background
    cartColor2: "#ffbb2a",
    itemPrice: "white",
    selectedCatBg: "#558b2f",
    selectedSubCatBg: "#558b2f"
  },
  { //Black Theme 2
    //theme 9
    fontFamily: "\'RobotoCondensed\'",
    sidebarWidth: 100,
    headerHeight: 40,
    maxPax: 30,
    sidebarFixedSize: true,

    headerLogoPosition: "center",
    topBarSubBg: "#424242",
    primaryColor: "#424242",
    secondaryColor: "#424242", //side bar background
    // checkinPrimaryColor: "#1e1e1e",

    selectedColor: "#Cf000d",

    menuHeaderColor: "#424242", //menu top background
    menuTitleColor: "#424242",

    optionHeaderBackgroundColor: "#424242",  //"#455a64",
    optionHeaderColor: "white", //"#f8dd78",
    optionBorderColor: "#ddd",
    optionPriceColor: "white",

    backgroundColor: "#fff",

    cartColor1: "#f3962b",
    cartColor2: "#3fbd11",
    cartFont: "white",

    borderColor: "#ddd",

    fontPrimary: "#424242", //container detail font color
    fontSecondary: "white", //menu item color
    headerColor: "white",
    fontShadow: "#424242",
    fontPrice: "#cf000d",
    fontAlert: "red",
    selectedCategory: "#f8dd78",//category selected color
    unselectedCategory: "white", //category color
    fontItemPrice: 'white',
    checkOutArrow: "white", //checkOut Arrow Color
    itemPrice: "white",
    selectedCatBg: "#f3962b",
    selectedSubCatBg: "#f3962b"
  },
  { //Sky Blue Theme
    //theme 10
    fontFamily: "\'RobotoCondensed\'",
    sidebarWidth: 100,
    headerHeight: 40,
    maxPax: 30,
    sidebarFixedSize: true,

    headerLogoPosition: "center",
    topBarSubBg: "#4a94bb",
    primaryColor: "#4a94bb",
    secondaryColor: "#4a94bb", //side bar background
    // checkinPrimaryColor: "#1e1e1e",

    selectedColor: "#Cf000d",

    menuHeaderColor: "#4a94bb", //menu top background
    menuTitleColor: "#4a94bb",

    optionHeaderBackgroundColor: "#4a94bb",  //"#455a64",
    optionHeaderColor: "white", //"#f8dd78",
    optionBorderColor: "#ddd",
    optionPriceColor: "white",

    backgroundColor: "#fff",

    cartColor1: "#f3962b",
    cartColor2: "#3fbd11",
    cartFont: "white",

    borderColor: "#ddd",

    fontPrimary: "#4a94bb", //container detail font color
    fontSecondary: "white", //menu item color
    headerColor: "white",
    fontShadow: "#4a94bb",
    fontPrice: "#cf000d",
    fontAlert: "red",
    selectedCategory: "#f8dd78",//category selected color
    unselectedCategory: "white", //category color
    fontItemPrice: 'white',
    checkOutArrow: "white", //checkOut Arrow Color
    itemPrice: "white",
    selectedCatBg: "#f3962b",
    selectedSubCatBg: "#f3962b"
  },
  { //Dark Green Theme
    //theme 11
    fontFamily: "\'RobotoCondensed\'",
    sidebarWidth: 100,
    headerHeight: 40,
    maxPax: 30,
    sidebarFixedSize: true,

    headerLogoPosition: "center",
    topBarSubBg: "#0A433A",
    primaryColor: "#0A433A",
    secondaryColor: "#0A433A", //side bar background
    // checkinPrimaryColor: "#1e1e1e",

    selectedColor: "#Cf000d",

    menuHeaderColor: "#0A433A", //menu top background
    menuTitleColor: "#0A433A",

    optionHeaderBackgroundColor: "#0A433A",  //"#455a64",
    optionHeaderColor: "#FFD57F", //"#f8dd78",
    optionBorderColor: "#ddd",
    optionPriceColor: "#FFD57F",

    backgroundColor: "#fff",

    cartColor1: "#f3962b",
    cartColor2: "#3fbd11",
    cartFont: "white",

    borderColor: "#ddd",

    fontPrimary: "#0A433A", //container detail font color
    fontSecondary: "#FFD57F", //menu item color
    headerColor: "#FFD57F",
    fontShadow: "#0A433A",
    fontPrice: "#cf000d",
    fontAlert: "red",
    selectedCategory: "#f8dd78",//category selected color
    unselectedCategory: "#FFD57F", //category color
    fontItemPrice: '#FFD57F',
    checkOutArrow: "#FFD57F", //checkOut Arrow Color
    itemPrice: "white",
    selectedCatBg: "#f3962b",
    selectedSubCatBg: "#f3962b"
  },
  { //Biege Theme
    //theme 12
    fontFamily: "\'RobotoCondensed\'",
    sidebarWidth: 100,
    headerHeight: 40,
    maxPax: 30,
    sidebarFixedSize: true,

    headerLogoPosition: "center",
    topBarSubBg: "#292218",
    primaryColor: "#af231c",
    secondaryColor: "#292218", //side bar background
    // checkinPrimaryColor: "#1e1e1e",

    selectedColor: "#af231c",

    menuHeaderColor: "#292218", //menu top background
    menuTitleColor: "#292218",

    optionHeaderBackgroundColor: "#af231c",  //"#455a64",
    optionHeaderColor: "#af231c", //"#f8dd78",
    optionBorderColor: "#ddd",
    optionPriceColor: "#FFD57F",

    backgroundColor: "#f3e9df",

    cartColor1: "#af231c",
    cartColor2: "#af231c",
    cartFont: "white",

    borderColor: "#ddd",

    fontPrimary: "#292218", //container detail font color
    fontSecondary: "#FFD57F", //menu item color
    headerColor: "#FFD57F",
    fontShadow: "#292218",
    fontPrice: "#cf000d",
    fontAlert: "red",
    selectedCategory: "#f8dd78",//category selected color
    unselectedCategory: "#FFD57F", //category color
    fontItemPrice: '#FFD57F',
    checkOutArrow: "#FFD57F", //checkOut Arrow Color
    itemPrice: "white",
    selectedCatBg: "#af231c",
    selectedSubCatBg: "#af231c",

  },
  { //Jungle Theme
    //theme 13
    fontFamily: "\'RobotoCondensed\'",
    sidebarWidth: 100,
    headerHeight: 40,
    maxPax: 30,
    sidebarFixedSize: true,

    headerLogoPosition: "center",
    topBarSubBg: "#0d261c",
    primaryColor: "#0d261c",
    secondaryColor: "#292218", //side bar background
    checkInPrimaryColor: "white",

    selectedColor: "#0d261c",

    menuHeaderColor: "#0d261c", //menu top background
    menuTitleColor: "#0d261c",

    optionHeaderBackgroundColor: "#0d261c",  //"#455a64",
    optionHeaderColor: "#0d261c", //"#f8dd78",
    optionBorderColor: "#ddd",
    optionPriceColor: "#CEF580",

    backgroundColor: "#f3e9df",

    cartColor1: "#0d261c",
    cartColor2: "#0d261c",
    cartFont: "white",

    borderColor: "#ddd",

    fontPrimary: "#292218", //container detail font color
    fontSecondary: "#CEF580", //menu item color
    headerColor: "#CEF580",
    fontShadow: "#292218",
    fontPrice: "#cf000d",
    fontAlert: "red",
    selectedCategory: "#0d261c",//category selected color
    unselectedCategory: "#CEF580", //category color
    fontItemPrice: '#CEF580',
    checkOutArrow: "#CEF580", //checkOut Arrow Color
    itemPrice: "white",
    selectedCatBg: "#CEF580",
    selectedSubCatBg: "#CEF580",
    selectedCatColor: "#0d261c",
    checkInbackgroundColor: "#0d261c",
    catBackgroundColor: "#0d261c",
    checkInEnterColor:"#0d261c"
  },
  { //Black Theme
    //theme 14
    fontFamily: "\'RobotoCondensed\'",
    sidebarWidth: 100,
    headerHeight: 40,
    maxPax: 30,
    sidebarFixedSize: true,

    headerLogoPosition: "center",
    topBarSubBg: "black",
    primaryColor: "black",
    secondaryColor: "#1e1e1e", //side bar background
    // checkinPrimaryColor: "#1e1e1e",

    selectedColor: "#Cf000d",

    menuHeaderColor: "#1e1e1e", //menu top background
    menuTitleColor: "black",

    optionHeaderBackgroundColor: "#2B57AD",  //"#455a64",
    optionHeaderColor: "#f8dd78", //"#f8dd78",
    optionBorderColor: "#ddd",
    optionPriceColor: "white",

    backgroundColor: "#fff",

    cartColor1: "#2B57AD",
    cartColor2: "#2B57AD",
    cartFont: "white",

    borderColor: "#ddd",

    fontPrimary: "black", //container detail font color
    fontSecondary: "#f8dd78", //menu item color
    headerColor: "#f8dd78",
    fontShadow: "black",
    fontPrice: "#cf000d",
    fontAlert: "red",
    selectedCategory: "white",//category selected color
    unselectedCategory: "#f8dd78", //category color
    fontItemPrice: '#f8dd78',
    checkOutArrow: "#f8dd78", //checkOut Arrow Color
    itemPrice: "white",
    selectedCatBg: "#2B57AD",
    selectedSubCatBg: "#2B57AD"
  },
  { //Black Theme 4
    //theme 15
    fontFamily: "\'Copperplate\'",
    sidebarWidth: 100,
    headerHeight: 40,
    maxPax: 30,
    sidebarFixedSize: true,

    headerLogoPosition: "center",
    topBarSubBg: "black",
    primaryColor: "black",
    secondaryColor: "#1e1e1e", //side bar background
    // checkinPrimaryColor: "#1e1e1e",

    selectedColor: "#Cf000d",

    menuHeaderColor: "#1e1e1e", //menu top background
    menuTitleColor: "black",

    optionHeaderBackgroundColor: "#1e1e1e",  //"#455a64",
    optionHeaderColor: "white", //"#f8dd78",
    optionBorderColor: "#ddd",
    optionPriceColor: "white",

    backgroundColor: "#fff",

    cartColor1: "#f3962b",
    cartColor2: "#3fbd11",
    cartFont: "white",

    borderColor: "#ddd",

    fontPrimary: "black", //container detail font color
    fontSecondary: "white", //menu item color
    headerColor: "white",
    fontShadow: "black",
    fontPrice: "#cf000d",
    fontAlert: "red",
    selectedCategory: "white",//category selected color
    unselectedCategory: "white", //category color
    fontItemPrice: '#f8dd78',
    checkOutArrow: "#f8dd78", //checkOut Arrow Color
    itemPrice: "white",
    selectedCatBg: "#f3962b",
    selectedSubCatBg: "#f3962b"
  },
  { //Green Theme 3
    //theme 16
    //467941
    fontFamily: "\'RobotoCondensed\'",
    sidebarWidth: 100,
    headerHeight: 40,
    maxPax: 30,
    sidebarFixedSize: true,

    headerLogoPosition: "center",
    topBarSubBg: "#467941",
    primaryColor: "#467941",
    secondaryColor: "#467941", //side bar background
    // checkinPrimaryColor: "#1e1e1e",

    selectedColor: "#Cf000d",

    menuHeaderColor: "#467941", //menu top background
    menuTitleColor: "#467941",

    optionHeaderBackgroundColor: "#467941",  //"#455a64",
    optionHeaderColor: "#FFD57F", //"#f8dd78",
    optionBorderColor: "#ddd",
    optionPriceColor: "#FFD57F",

    backgroundColor: "#fff",

    cartColor1: "#f3962b",
    cartColor2: "#3fbd11",
    cartFont: "white",

    borderColor: "#ddd",

    fontPrimary: "#467941", //container detail font color
    fontSecondary: "#FFD57F", //menu item color
    headerColor: "#FFD57F",
    fontShadow: "#467941",
    fontPrice: "#cf000d",
    fontAlert: "red",
    selectedCategory: "#f8dd78",//category selected color
    unselectedCategory: "#FFD57F", //category color
    fontItemPrice: '#FFD57F',
    checkOutArrow: "#FFD57F", //checkOut Arrow Color
    itemPrice: "white",
    selectedCatBg: "#f3962b",
    selectedSubCatBg: "#f3962b"
  },
];
