/* eslint-disable no-console */
import React, { useMemo, useReducer, useEffect, useState } from "react";
import NewMenu from "./screens/Menu.js";
import { themes } from "./styles/themes";
import Checkout from "./screens/Checkout.js";
import PromotionScreen from "./screens/Promotion.js";
import Checkin from "./screens/Checkin.js";
import Success from "./screens/Success.js";
import Category from "./screens/Category.js";
import Fail from "./screens/Fail.js";
import Cancel from "./screens/Cancel.js";
import OrderFail from './screens/OrderFail.js'
import CustomerReset from './newcomponents/customerComponents/CustomerReset';
import CustomerLogin from './screens/CustomerLogin.js';
import axios from "axios";
import { Router } from "@reach/router";
import { UserContext, ActionContext, ThemeContext, LanguageContext } from "./contexts/Context";
import { language } from './contexts/languageContext';
import PaymentSummary from './screens/PaymentSummary';
import CustomerDetail from './screens/CustomerDetail.js';

function App() {
  // State (Reducer)
  // Initial UserState
  const initialUserState = {
    menuList: [],
    // userId: "1df2sssdsf",
    // orderTime: "1619075892765",
    // token: "5e33a1632c937e6a452a1a4f",
    tableLabel: "Loading",
    isTakeAway: false,
    isInRoom: false,
    isMenuView: false,
    takeAwayId: "",
    title: "",
    pax: 0,
    cart: [],
    lanIndex: 0,
    ads: [],
    company: { companyName: "POS" },
    tax: {},
    menus: [],
    paymentData: {},
    icons: [],
    skipPaxControl: false,
    allowTAafterWH: true,
    deliveryOrNot: true,
    hasTakeAway: true,
    deliveryCosts: [],
    minimumConsumption: 0,
    checkOutRec: {},
    isForCheckout: false,
    haveToPayOnline: false,
    isOnlinePayMinConsumption: false,
    onlinePayMinConsumptionVal: 0,
    checkboxs: [],
    hasCategoryScreen: false,
    tkType: 0,
    hasAdvocado: false,
    isSearch: false,
    searchTxt: "",
    isSearching: false,
    displayNetAmount: false,
    hasMember: false,
    haveToLogin: false,
    allowRegister: false,
    hasVoucher: false,
    vouchers: [],
    sameDayOrder: false,
    hideOptionalField: false,
    takeAwayInfo: {}
  };

  //Set to themes[0] in production build
  const [themeContext, setTheme] = useState(themes[0]); // 1,3
  const [languageContext, setLanguage] = useState(language);
  //Memo Actions

  const actionContext = useMemo(
    () => ({
      payByAdvocado: (isAdvocadoPayment) => {
        dispatchUserAction({
          type: "PAY_BY_ADVOCADO",
          isAdvocadoPayment: isAdvocadoPayment
        });
      },
      setSearching: (isSearching) => {
        dispatchUserAction({
          type: "IS_SEARCHING",
          isSearching: isSearching
        });
      },
      setIsSearch: (isSearch) => {
        dispatchUserAction({
          type: "IS_SEARCH",
          isSearch: isSearch,
        });
      },
      setSearchTxt: (searchTxt) => {
        dispatchUserAction({
          type: "SET_SEARCHTXT",
          searchTxt: searchTxt,
        });
      },
      setScrolling: (isScrolling) => {
        dispatchUserAction({
          type: "IS_SCROLLING",
          isScrolling: isScrolling,
        });
      },
      addAdCustomer: (adCustomer) => {
        dispatchUserAction({
          type: "ADD_ADCUSTOMER",
          adCustomer: adCustomer
        })
      },
      addTakeAwayInfo: (takeAwayInfo) => {
        dispatchUserAction({
          type: "ADD_TAKEAWAY_INFO",
          takeAwayInfo: takeAwayInfo
        })
      },
      addCustomer: (customer) => {
        dispatchUserAction({
          type: "ADD_CUSTOMER",
          customer: customer
        })
      },
      addTakeAwayId: (takeAwayId) => {
        dispatchUserAction({
          type: "ADD_TAKEAWAY_ID",
          takeAwayId: takeAwayId,
        });
      },
      setTkType: (tkType) => {
        dispatchUserAction({
          type: "ADD_TAKEAWAY_TYPE",
          tkType: tkType,
        });
      },
      setReserveData: (reserveData) => {
        dispatchUserAction({
          type: "SET_RESERVE_DATA",
          reserveData: reserveData
        })
      },
      setRedeem: (redeem) => {
        dispatchUserAction({
          type: "SET_REDEEM",
          redeem: redeem
        });
      },
      setDiscount: (discount) => {
        dispatchUserAction({
          type: "SET_DISCOUNT",
          discount: discount
        })
      },
      setFinalOrder: (finalOrder) => {
        dispatchUserAction({
          type: "SET_FINAL_ORDER",
          finalOrder: finalOrder,
        });
      },
      addVoucher: (vouchers) => {
        dispatchUserAction({
          type: "ADD_VOUCHER",
          vouchers: vouchers,
        });
      },
      addToCart: (cart) => {
        dispatchUserAction({
          type: "MODIFY_CART",
          cart: cart,
        });
      },

      clearCart: () => {
        dispatchUserAction({
          type: "MODIFY_CART",
          cart: [],
        });
        dispatchUserAction({
          type: "ADD_VOUCHER",
          cart: [],
        });
      },

      addRecommendation: (recommend) => {
        dispatchUserAction({
          type: "ADD_RECOMMEND",
          recommend: recommend
        })
      },
      removeFromCart: (cart, index) => {
        let handleRemoveIndex = (draft) => {
          return draft;
        };
        let newCart = handleRemoveIndex(cart, index);
        dispatchUserAction({
          type: "MODIFY_CART",
          cart: newCart,
        });
      },
      setMenu: (menuList) => {
        dispatchUserAction({
          type: "SET_MENU",
          menuList: menuList,
        });
      },
      setContainerItems: (containerItems) => {
        dispatchUserAction({
          type: "SET_CONTAINER",
          containerItems: containerItems
        });
      },
      addPromotion: (pm) => {
        localStorage.setItem("promotion", JSON.stringify(pm));
        dispatchUserAction({
          type: "SET_PROMOTION",
          pm: pm
        })
      },
      registerUserData: (token, userId, orderTime) => {
        localStorage.setItem("token", token);
        localStorage.setItem("userId", userId);
        localStorage.setItem("orderTime", orderTime);

        dispatchUserAction({
          type: "REGISTER_USERDATA",
          userId: userId,
          orderTime: orderTime,
          token: token,
        });
      },
      registerPax: (pax) => {
        localStorage.setItem("pax", pax);

        dispatchUserAction({
          type: "REGISTER_PAX",
          pax: pax,
        });
      },
      registerAds: (ads) => {
        localStorage.setItem("ads", ads);

        dispatchUserAction({
          type: "REGISTER_ADS",
          ads: ads,
        });
      },
      registerMenuTitle: (menus) => {
        dispatchUserAction({
          type: "REGISTER_MENU",
          menus: menus
        })
      },
      registerTableLabel: (tableLabel, isTakeAway, isMenuView, isInRoom) => {
        localStorage.setItem("tableLabel", isTakeAway ? "Takeaway" : isMenuView ? "E Menu" : tableLabel);
        localStorage.setItem("isTakeAway", isTakeAway);
        localStorage.setItem("isMenuView", isMenuView);
        localStorage.setItem("isInRoom", isInRoom);
        dispatchUserAction({
          type: "REGISTER_TABLE",
          tableLabel: isTakeAway ? "Takeaway" : tableLabel,
          isTakeAway: isTakeAway,
          isMenuView: isMenuView,
          isInRoom: isInRoom
        });
      },
      registerCompany: (company) => {
        localStorage.setItem("company", company);

        dispatchUserAction({
          type: "REGISTER_COMPANY",
          company: company,
        });
      },
      registerPaymentData: (paymentData) => {
        localStorage.setItem("paymentData", paymentData);

        dispatchUserAction({
          type: "REGISTER_PAYMENTDATA",
          paymentData: paymentData,
        });
      },
      registerTax: (tax) => {
        localStorage.setItem("tax", tax);

        dispatchUserAction({
          type: "REGISTER_TAX",
          tax: tax,
        });
      },
      registerUISetup: (icons, bgImg, landingImg, greetingMessage, tkSuccessMessage, theme, supportMultiLan,
        hasPickUpTime, tkOrderAfter, startTime, endTime, currencySymbol, disSubCatAtSide, skipPaxControl,
        weekDays, allowTAafterWH, deliveryOrNot, deliveryCosts, minimumConsumption, isForCheckout, checkOutRec,
        isOnlinePayMinConsumption, onlinePayMinConsumptionVal, checkboxs,
        addItemDirect, unlimitedScroll, catPos, itemLayout, hasCategoryScreen, hasAdvocado, includeDineIn,
        tkDiMenuDiff, skipCustomerDetail, displayNetAmount, hasTakeAway, hasMember, haveToLogin, successMessage, allowRegister,
        advocadoRedeem, skipDIPickUpTime, showLogo, hidePrice, isLanReverse, paymentSelectionMessage, mustDob, payAtCounter, hasVoucher,
        sameDayOrder, hideOptionalField,
        deliveryCalMethod,
        deliveryDisAmt, //if deliveryCalMethod is 1, has discount if more than certain
        deliverySpend, selectPUTimeFirst,
        showKitchenStatus,
        showPickUpInfo,
        pickUpPoint, advocadoStoreCredit, recentBought, memberRedeem) => {
        localStorage.setItem("icons", icons);
        localStorage.setItem("theme", theme);
        localStorage.setItem("tkSuccessMessage", tkSuccessMessage);
        localStorage.setItem("supportMultiLan", supportMultiLan);
        localStorage.setItem("hasPickUpTime", hasPickUpTime);
        localStorage.setItem("tkOrderAfter", tkOrderAfter);
        localStorage.setItem("startTime", startTime);
        localStorage.setItem("endTime", endTime);
        localStorage.setItem('currencySymbol', currencySymbol);
        localStorage.setItem('disSubCatAtSide', disSubCatAtSide);
        localStorage.setItem('skipPaxControl', skipPaxControl);
        localStorage.setItem('weekDays', JSON.stringify(weekDays));
        localStorage.setItem('allowTAafterWH', allowTAafterWH);
        localStorage.setItem('deliveryOrNot', deliveryOrNot);
        localStorage.setItem('deliveryCosts', JSON.stringify(deliveryCosts));
        localStorage.setItem('minimumConsumption', minimumConsumption);
        localStorage.setItem('isForCheckout', isForCheckout);
        localStorage.setItem('checkOutRec', JSON.stringify(checkOutRec));
        localStorage.setItem('checkboxs', JSON.stringify(checkboxs));
        localStorage.setItem('isOnlinePayMinConsumption', isOnlinePayMinConsumption);
        localStorage.setItem('onlinePayMinConsumptionVal', onlinePayMinConsumptionVal);
        localStorage.setItem('bgImg', bgImg);
        localStorage.setItem('landingImg', landingImg);
        localStorage.setItem('addItemDirect', addItemDirect);
        localStorage.setItem('unlimitedScroll', unlimitedScroll);
        localStorage.setItem('catPos', catPos);
        localStorage.setItem('itemLayout', itemLayout);
        localStorage.setItem('hasCategoryScreen', hasCategoryScreen);
        localStorage.setItem('hasAdvocado', hasAdvocado);
        localStorage.setItem('includeDineIn', includeDineIn);
        localStorage.setItem('tkDiMenuDiff', tkDiMenuDiff);
        localStorage.setItem('skipCustomerDetail', skipCustomerDetail);
        localStorage.setItem('displayNetAmount', displayNetAmount);
        localStorage.setItem('hasTakeAway', hasTakeAway);
        localStorage.setItem('hasMember', hasMember);
        localStorage.setItem('haveToLogin', haveToLogin);
        localStorage.setItem('successMessage', successMessage);
        localStorage.setItem('allowRegister', allowRegister);
        localStorage.setItem("advocadoRedeem", advocadoRedeem);
        localStorage.setItem("skipDIPickUpTime", skipDIPickUpTime);
        localStorage.setItem("showLogo", showLogo);
        localStorage.setItem("hidePrice", hidePrice);
        localStorage.setItem("isLanReverse", isLanReverse);
        localStorage.setItem("paymentSelectionMessage", paymentSelectionMessage);
        localStorage.setItem('mustDob', mustDob);
        localStorage.setItem('payAtCounter', payAtCounter);
        localStorage.setItem('hasVoucher', hasVoucher);
        localStorage.setItem('sameDayOrder', sameDayOrder);
        localStorage.setItem('hideOptionalField', hideOptionalField);
        localStorage.setItem('deliveryCalMethod', deliveryCalMethod);
        localStorage.setItem('deliveryDisAmt', deliveryDisAmt);
        localStorage.setItem('deliverySpend', deliverySpend);
        localStorage.setItem('selectPUTimeFirst', selectPUTimeFirst);
        localStorage.setItem('showKitchenStatus', showKitchenStatus);
        localStorage.setItem('showPickUpInfo', showPickUpInfo);
        localStorage.setItem('pickUpPoint', JSON.stringify(pickUpPoint));
        localStorage.setItem('advocadoStoreCredit', advocadoStoreCredit);
        localStorage.setItem('recentBought', recentBought);
        localStorage.setItem('memberRedeem', memberRedeem);

        if (isLanReverse) {
          dispatchUserAction({
            type: "REGISTER_LANINDEX",
            lanIndex: 1
          });
        }
        dispatchUserAction({
          type: "REGISTER_ICONS",
          icons: icons,
        });
        dispatchUserAction({
          type: "REGISTER_BACKGROUNDIMAGE",
          bgImg: bgImg,
          landingImg: landingImg,
          supportMultiLan,
          hasPickUpTime,
          tkOrderAfter,
          startTime,
          endTime,
          currencySymbol,
          disSubCatAtSide,
          skipPaxControl,
          weekDays,
          allowTAafterWH,
          deliveryOrNot,
          deliveryCosts,
          minimumConsumption,
          isForCheckout,
          checkOutRec,
          isOnlinePayMinConsumption,
          onlinePayMinConsumptionVal,
          checkboxs,
          addItemDirect,
          unlimitedScroll,
          catPos,
          itemLayout,
          hasCategoryScreen,
          hasAdvocado,
          includeDineIn,
          tkDiMenuDiff,
          skipCustomerDetail,
          displayNetAmount,
          hasTakeAway,
          hasMember,
          haveToLogin,
          successMessage,
          allowRegister,
          advocadoRedeem,
          skipDIPickUpTime,
          showLogo,
          hidePrice,
          isLanReverse,
          paymentSelectionMessage,
          mustDob,
          payAtCounter,
          hasVoucher,
          sameDayOrder,
          hideOptionalField,
          deliveryCalMethod,
          deliveryDisAmt, //if deliveryCalMethod is 1, has discount if more than certain
          deliverySpend,
          selectPUTimeFirst,
          showKitchenStatus,
          showPickUpInfo,
          pickUpPoint,
          advocadoStoreCredit,
          recentBought,
          memberRedeem
        });
        dispatchUserAction({
          type: "REGISTER_GREETINGMESSAGE",
          greetingMessage: greetingMessage,
          tkSuccessMessage: tkSuccessMessage,
          successMessage: successMessage
        });
        dispatchUserAction({
          type: "REGISTER_THEME",
          theme: theme
        });
      },
      registerOtherUrl: (otherUrls) => {
        localStorage.setItem("otherUrl", otherUrls);
        dispatchUserAction({
          type: "REGISTER_OTHERURLS",
          otherUrls: otherUrls,
        });
      },

      registerAsiaPay: (asiaPay, hasPayment, paymentPlatform, PaymentTakeawayOnly, isDemo, haveToPayOnline) => {
        localStorage.setItem("asiaPay", asiaPay);
        localStorage.setItem("hasPayment", hasPayment);
        localStorage.setItem("paymentPlatform", paymentPlatform);
        localStorage.setItem("PaymentTakeawayOnly", PaymentTakeawayOnly);
        localStorage.setItem('haveToPayOnline', haveToPayOnline);
        dispatchUserAction({
          type: "REGISTER_ASIAPAY",
          asiaPay: asiaPay,
          hasPayment: hasPayment,
          PaymentTakeawayOnly: PaymentTakeawayOnly,
          paymentPlatform: paymentPlatform,
          isDemo: isDemo,
          haveToPayOnline
        });
      },
      updateTitle: (title) => {
        localStorage.setItem("TITLE", title);
        dispatchUserAction({
          type: "UPDATE_TITLE",
          title: title
        })
      },
      setLanIndex: (lanIndex) => {
        localStorage.setItem("lanIndex", lanIndex);
        dispatchUserAction({
          type: "REGISTER_LANINDEX",
          lanIndex: lanIndex
        });
      },
      setRecentItem: (recentItem) => {
        dispatchUserAction({
          type: "SET_RECENT_ITEM",
          recentItem: recentItem
        });
      },
    }),
    []
  );

  //User Reducer
  const userReducer = (prevState, action) => {
    switch (action.type) {
      case "IS_SEARCH":
        return {
          ...prevState,
          isSearch: action.isSearch
        }
      case "IS_SEARCHING":
        return {
          ...prevState,
          isSearching: action.isSearching
        }
      case "SET_SEARCHTXT":
        return {
          ...prevState,
          searchTxt: action.searchTxt
        }
      case "IS_SCROLLING":
        return {
          ...prevState,
          isScrolling: action.isScrolling
        };
      case "ADD_ADCUSTOMER":
        return {
          ...prevState,
          adCustomer: action.adCustomer
        }
      case "ADD_TAKEAWAY_INFO":
        return {
          ...prevState,
          takeAwayInfo: action.takeAwayInfo
        }
      case "ADD_CUSTOMER":
        return {
          ...prevState,
          customer: action.customer
        }
      case "PAY_BY_ADVOCADO":
        return {
          ...prevState,
          isAdvocadoPayment: action.isAdvocadoPayment
        }
      case "ADD_TAKEAWAY_ID":
        return {
          ...prevState,
          takeAwayId: action.takeAwayId
        };
      case "ADD_TAKEAWAY_TYPE":
        return {
          ...prevState,
          tkType: action.tkType
        }
      case "SET_RESERVE_DATA":
        return {
          ...prevState,
          reserveData: action.reserveData
        }
      case "MODIFY_CART":
        return {
          ...prevState,
          cart: action.cart,
        };

      case "ADD_VOUCHER":
        return {
          ...prevState,
          vouchers: action.vouchers
        }
      case "SET_FINAL_ORDER":
        return {
          ...prevState,
          finalOrder: action.finalOrder
        }
      case "SET_REDEEM":
        return {
          ...prevState,
          redeem: action.redeem
        }
      case "SET_DISCOUNT":
        return {
          ...prevState,
          discount: action.discount
        }
      case "ADD_RECOMMEND":
        return {
          ...prevState,
          recommend: action.recommend,
        };
      case "SET_MENU":
        return {
          ...prevState,
          menuList: action.menuList,
        };
      case "SET_CONTAINER":
        return {
          ...prevState,
          containerItems: action.containerItems
        }
      case "REGISTER_USERDATA":
        return {
          ...prevState,
          userId: action.userId,
          orderTime: action.orderTime,
          token: action.token,
        };
      case "REGISTER_PAX":
        return {
          ...prevState,
          pax: action.pax,
        };

      case "REGISTER_ADS":
        return {
          ...prevState,
          ads: action.ads,
        };

      case "REGISTER_COMPANY":
        return {
          ...prevState,
          company: action.company,
        };

      case "REGISTER_TABLE":
        return {
          ...prevState,
          tableLabel: action.tableLabel,
          isTakeAway: action.isTakeAway,
          isMenuView: action.isMenuView,
          isInRoom: action.isInRoom
        };
      case "REGISTER_PAYMENTDATA":
        return {
          ...prevState,
          paymentData: action.paymentData,
        };
      case "REGISTER_TAX":
        return {
          ...prevState,
          tax: action.tax,
        };
      case "SET_PROMOTION":
        return {
          ...prevState,
          pm: action.pm
        }
      case "REGISTER_ICONS":
        return {
          ...prevState,
          icons: action.icons,
        };
      case "REGISTER_BACKGROUNDIMAGE":
        return {
          ...prevState,
          bgImg: action.bgImg,
          landingImg: action.landingImg,
          supportMultiLan: action.supportMultiLan,
          hasPickUpTime: action.hasPickUpTime,
          tkOrderAfter: action.tkOrderAfter,
          startTime: action.startTime,
          endTime: action.endTime,
          currencySymbol: action.currencySymbol,
          disSubCatAtSide: action.disSubCatAtSide,
          skipPaxControl: action.skipPaxControl,
          weekDays: action.weekDays,
          allowTAafterWH: action.allowTAafterWH,
          deliveryOrNot: action.deliveryOrNot,
          deliveryCosts: action.deliveryCosts,
          minimumConsumption: action.minimumConsumption,
          isForCheckout: action.isForCheckout,
          checkOutRec: action.checkOutRec,
          isOnlinePayMinConsumption: action.isOnlinePayMinConsumption,
          onlinePayMinConsumptionVal: action.onlinePayMinConsumptionVal,
          checkboxs: action.checkboxs,
          addItemDirect: action.addItemDirect,
          unlimitedScroll: action.unlimitedScroll,
          catPos: action.catPos,
          itemLayout: action.itemLayout,
          hasCategoryScreen: action.hasCategoryScreen,
          hasAdvocado: action.hasAdvocado,
          includeDineIn: action.includeDineIn,
          tkDiMenuDiff: action.tkDiMenuDiff,
          skipCustomerDetail: action.skipCustomerDetail,
          displayNetAmount: action.displayNetAmount,
          hasTakeAway: action.hasTakeAway,
          hasMember: action.hasMember,
          haveToLogin: action.haveToLogin,
          successMessage: action.successMessage,
          allowRegister: action.allowRegister,
          advocadoRedeem: action.advocadoRedeem,
          skipDIPickUpTime: action.skipDIPickUpTime,
          showLogo: action.showLogo,
          hidePrice: action.hidePrice,
          isLanReverse: action.isLanReverse,
          paymentSelectionMessage: action.paymentSelectionMessage,
          mustDob: action.mustDob,
          payAtCounter: action.payAtCounter,
          hasVoucher: action.hasVoucher,
          sameDayOrder: action.sameDayOrder,
          hideOptionalField: action.hideOptionalField,
          deliveryCalMethod: action.deliveryCalMethod,
          deliveryDisAmt: action.deliveryDisAmt, //if deliveryCalMethod is 1, has discount if more than certain
          deliverySpend: action.deliverySpend,
          selectPUTimeFirst: action.selectPUTimeFirst,
          showKitchenStatus: action.showKitchenStatus,
          showPickUpInfo: action.showPickUpInfo,
          pickUpPoint: action.pickUpPoint,
          advocadoStoreCredit: action.advocadoStoreCredit,
          recentBought: action.recentBought,
          memberRedeem: action.memberRedeem
        };
      case "REGISTER_GREETINGMESSAGE":
        return {
          ...prevState,
          greetingMessage: action.greetingMessage,
          tkSuccessMessage: action.tkSuccessMessage,
          successMessage: action.successMessage
        };
      case "REGISTER_THEME":
        return {
          ...prevState,
          theme: action.theme
        }
      case "REGISTER_ASIAPAY":
        return {
          ...prevState,
          hasPayment: action.hasPayment,
          asiaPay: action.asiaPay,
          paymentPlatform: action.paymentPlatform,
          PaymentTakeawayOnly: action.PaymentTakeawayOnly,
          isDemo: action.isDemo,
          haveToPayOnline: action.haveToPayOnline
        };
      case "REGISTER_OTHERURLS":
        return {
          ...prevState,
          otherUrls: action.otherUrls,
        };
      case "REGISTER_MENU":
        return {
          ...prevState,
          menus: action.menus
        };
      case "UPDATE_TITLE":
        return {
          ...prevState,
          title: action.title
        }
      case "REGISTER_LANINDEX":
        return {
          ...prevState,
          lanIndex: action.lanIndex
        }
      case "SET_RECENT_ITEM":
        return {
          ...prevState,
          recentItem: action.recentItem
        }
    }
  };
  //useReducer Init
  const [userState, dispatchUserAction] = useReducer(
    userReducer,
    initialUserState
  );

  //UseEffect
  useEffect(() => {
    let token = "";
    let isDemo = false;
    let userId = "";
    let orderTime = "";
    let pax = -1;
    let icons = ["/Promo1.png", "/Promo2.png", "/Promo3.png"];
    let bgImg = "";
    let landingImg = "";
    let title = "";
    let currencySymbol = "SGD";
    let paymentPlatform = 0;
    let hasPayment = true;
    let PaymentTakeawayOnly = false;
    let asiaPay = {};
    let theme = 0;
    let greetingMessage = `Welcome!\nPlease only order one at a time!`;
    let tkSuccessMessage = "";
    let successMessage = "";
    let disSubCatAtSide = true;
    let allowTAafterWH = true;
    let deliveryOrNot = true;
    let hasTakeAway = true;
    let deliveryCosts = [];
    let minimumConsumption = 0;
    let checkOutRec = {};
    let checkboxs = [];
    let isOnlinePayMinConsumption = false;
    let onlinePayMinConsumptionVal = 0;
    let isForCheckout = false;
    let addItemDirect = false;
    let unlimitedScroll = true;
    let catPos = 1;
    let itemLayout = 0;
    let hasCategoryScreen = false;
    let hasAdvocado = false;
    let includeDineIn = true;
    let tkDiMenuDiff = false;
    let hasMember = true;
    let haveToLogin = false;
    let allowRegister = false;
    //comment out to test
    bgImg = localStorage.getItem('bgImg');
    landingImg = localStorage.getItem('landingImg');
    pax = localStorage.getItem("pax");
    token = localStorage.getItem("token");
    isDemo = localStorage.getItem("isDemo");
    userId = localStorage.getItem("userId");
    orderTime = localStorage.getItem("orderTime");
    title = localStorage.getItem("TITLE");
    asiaPay = localStorage.getItem("asiaPay", asiaPay);
    hasPayment = localStorage.getItem("hasPayment", hasPayment) != null ? localStorage.getItem("hasPayment", hasPayment) : hasPayment;
    PaymentTakeawayOnly = localStorage.getItem("PaymentTakeawayOnly") != null ? localStorage.getItem("PaymentTakeawayOnly") : PaymentTakeawayOnly;
    paymentPlatform = localStorage.getItem("paymentPlatform", paymentPlatform);
    tkSuccessMessage = localStorage.getItem('tkSuccessMessage');
    successMessage = localStorage.getItem('successMessage');
    theme = localStorage.getItem('theme') != null ? localStorage.getItem("theme") : 1;
    currencySymbol = localStorage.getItem('currencySymbol') != null ? localStorage.getItem('currencySymbol') : "SGD";
    disSubCatAtSide = localStorage.getItem("disSubCatAtSide") != null ? localStorage.getItem("disSubCatAtSide") : disSubCatAtSide;
    allowTAafterWH = localStorage.getItem('allowTAafterWH') != null ? localStorage.getItem('allowTAafterWH') : allowTAafterWH;
    //dummy data
    deliveryOrNot = localStorage.getItem("deliveryOrNot") != null ? localStorage.getItem("deliveryOrNot") : deliveryOrNot;
    hasTakeAway = localStorage.getItem('hasTakeAway') != null ? localStorage.getItem('hasTakeAway') : hasTakeAway;
    hasMember = localStorage.getItem('hasMember') != null ? localStorage.getItem('hasMember') : hasMember;
    haveToLogin = localStorage.getItem('haveToLogin') != null ? localStorage.getItem('haveToLogin') : haveToLogin;
    allowRegister = localStorage.getItem('allowRegister') != null ? localStorage.getItem('allowRegister') : allowRegister;
    deliveryCosts = localStorage.getItem("deliveryCosts");
    addItemDirect = localStorage.getItem("addItemDirect") != null ? localStorage.getItem("addItemDirect") : addItemDirect;
    minimumConsumption = localStorage.getItem("minimumConsumption");
    checkOutRec = localStorage.getItem("checkOutRec");
    hasCategoryScreen = localStorage.getItem("hasCategoryScreen") != null ? localStorage.getItem("hasCategoryScreen") : hasCategoryScreen;
    hasAdvocado = localStorage.getItem("hasAdvocado") != null ? localStorage.getItem("hasAdvocado") : hasAdvocado;
    includeDineIn = localStorage.getItem("includeDineIn") != null ? localStorage.getItem("includeDineIn") : includeDineIn;
    tkDiMenuDiff = localStorage.getItem("tkDiMenuDiff") != null ? localStorage.getItem("tkDiMenuDiff") : tkDiMenuDiff;
    let skipCustomerDetail = localStorage.getItem("skipCustomerDetail") != null ? localStorage.getItem("skipCustomerDetail") : false;
    if (checkOutRec && checkOutRec != 'undefined' && checkOutRec != null) {
      checkOutRec = JSON.parse(checkOutRec);
    } else {
      checkOutRec = {};
    }
    isForCheckout = localStorage.getItem("isForCheckout");
    //new enhancement
    let supportMultiLan = localStorage.getItem("supportMultiLan") != null ? localStorage.getItem("supportMultiLan") : true;
    let hasPickUpTime = localStorage.getItem("hasPickUpTime");
    let tkOrderAfter = localStorage.getItem("tkOrderAfter");
    let startTime = localStorage.getItem("startTime") ? localStorage.getItem("startTime") : 0;
    let endTime = localStorage.getItem("endTime") ? localStorage.getItem("endTime") : 0;
    let skipPaxControl = localStorage.getItem("skipPaxControl") != null ? localStorage.getItem("skipPaxControl") : false;
    let displayNetAmount = localStorage.getItem("displayNetAmount") != null ? localStorage.getItem("displayNetAmount") : false;
    let weekDays = localStorage.getItem("weekDays") != null ? localStorage.getItem("weekDays") : "";
    let mustDob = localStorage.getItem('mustDob') != null ? localStorage.getItem('mustDob') : false;
    let payAtCounter = localStorage.getItem('payAtCounter') != null ? localStorage.getItem('payAtCounter') : false;
    let hasVoucher = localStorage.getItem('hasVoucher') != null ? localStorage.getItem('hasVoucher') : true;
    checkboxs = localStorage.getItem("checkboxs");
    isOnlinePayMinConsumption = localStorage.getItem("isOnlinePayMinConsumption") != null ? localStorage.getItem("isOnlinePayMinConsumption") : isOnlinePayMinConsumption;
    onlinePayMinConsumptionVal = localStorage.getItem("onlinePayMinConsumptionVal") != null ? localStorage.getItem("onlinePayMinConsumptionVal") : onlinePayMinConsumptionVal;
    let sameDayOrder = localStorage.getItem("sameDayOrder") != null ? localStorage.getItem('sameDayOrder') : false;
    let pm = localStorage.getItem("promotion") != null ? localStorage.getItem("promotion") : [];
    let hideOptionalField = localStorage.getItem("hideOptionalField") != null ? localStorage.getItem("hideOptionalField") : false;
    if (pm && typeof pm != 'undefined' && pm != null) {
      // pm = JSON.parse(pm);
      pm = [];
    } else {
      pm = [];
    }

    if (weekDays && weekDays != 'undefined' && weekDays != null) {
      weekDays = JSON.parse(weekDays);
    } else {
      weekDays = [];
    }
    if (deliveryCosts && deliveryCosts != 'undefined' && deliveryCosts != null) {
      deliveryCosts = JSON.parse(deliveryCosts);
    } else {
      deliveryCosts = [];
    }
    if (checkboxs && checkboxs != 'undefined' && checkboxs != null) {
      checkboxs = JSON.parse(checkboxs);
    } else {
      checkboxs = [];
    }
    let haveToPayOnline = localStorage.getItem('haveToPayOnline') != null ? localStorage.getItem('haveToPayOnline') : false;
    let isTakeAway = localStorage.getItem("isTakeAway") != null ? localStorage.getItem("isTakeAway") : false;
    let tableLabel = localStorage.getItem("tableLabel") != null ? localStorage.getItem("tableLabel") : "";
    let isMenuView = localStorage.getItem("isMenuView");
    unlimitedScroll = localStorage.getItem("unlimitedScroll") != null ? localStorage.getItem("unlimitedScroll") : unlimitedScroll;
    let advocadoRedeem = localStorage.getItem("advocadoRedeem") != null ? localStorage.getItem("advocadoRedeem") : false;
    let skipDIPickUpTime = localStorage.getItem("skipDIPickUpTime") != null ? localStorage.getItem("skipDIPickUpTime") : false;
    catPos = localStorage.getItem("catPos") != null ? localStorage.getItem("catPos") : catPos;
    itemLayout = localStorage.getItem("itemLayout") != null ? localStorage.getItem("itemLayout") : itemLayout;
    let company = { _id: "text", companyName: "-", companyLogo: "" };
    let showLogo = localStorage.getItem('showLogo') != null ? localStorage.getItem('showLogo') : false;
    let hidePrice = localStorage.getItem('hidePrice') != null ? localStorage.getItem('hidePrice') : false;
    let isLanReverse = localStorage.getItem('isLanReverse') != null ? localStorage.getItem('isLanReverse') : false;
    let vouchers = localStorage.getItem('vouchers') != null ? localStorage.getItem('vouchers') : '';
    let deliveryCalMethod = localStorage.getItem('deliveryCalMethod') != null ? localStorage.getItem('deliveryCalMethod') : 0;
    let deliveryDisAmt = localStorage.getItem('deliveryDisAmt') != null ? localStorage.getItem('deliveryDisAmt') : 0; //if deliveryCalMethod is 1, has discount if more than certain
    let deliverySpend = localStorage.getItem('deliverySpend') != null ? localStorage.getItem('deliverySpend') : 0;
    let selectPUTimeFirst = localStorage.getItem('selectPUTimeFirst') != null ? localStorage.getItem('selectPUTimeFirst') : false;
    if (vouchers) {
      vouchers = JSON.parse(vouchers);
    } else {
      vouchers = [];
    }

    let showKitchenStatus = localStorage.getItem('showKitchenStatus') != null ? localStorage.getItem('showKitchenStatus') : false;
    let showPickUpInfo = localStorage.getItem('showPickUpInfo') != null ? localStorage.getItem('showPickUpInfo') : false;
    let pickUpPoint = localStorage.getItem('pickUpPoint') != null ? localStorage.getItem('pickUpPoint') : '';
    if (pickUpPoint && typeof pickUpPoint != 'undefined' && pickUpPoint != null && pickUpPoint != 'undefined') {
      pickUpPoint = JSON.parse(pickUpPoint);
    } else {
      pickUpPoint = [];
    }
    let advocadoStoreCredit = localStorage.getItem('advocadoStoreCredit') != null ? localStorage.getItem('advocadoStoreCredit') : false;
    let recentBought = localStorage.getItem('recentBought') != null ? localStorage.getItem('recentBought') : false;
    let memberRedeem = localStorage.getItem('memberRedeem') != null ? localStorage.getItem('memberRedeem') : false;
    let paymentSelectionMessage = localStorage.getItem("paymentSelectionMessage") != null ? localStorage.getItem("paymentSelectionMessage") : ""
    let adCustomer = localStorage.getItem("adCustomer");
    if (adCustomer && adCustomer != null) {
      adCustomer = JSON.parse(adCustomer);
    }

    let customer = localStorage.getItem("customer");
    if (customer && customer != null) {
      customer = JSON.parse(customer);
    }

    let lanIndex = 0;
    supportMultiLan = supportMultiLan != null && supportMultiLan ? supportMultiLan : true;

    // axios.defaults.baseURL = "http://192.168.1.4:444";
    // axios.defaults.baseURL = "http://192.168.1.192:444";
    axios.defaults.baseURL = "https://selforder.ireachfnb.com";
    // axios.defaults.baseURL = "https://selforder2.chachingfnb.com";

    dispatchUserAction({
      type: "ADD_ADCUSTOMER",
      adCustomer: adCustomer
    });
    dispatchUserAction({
      type: "ADD_CUSTOMER",
      customer: customer
    });
    dispatchUserAction({
      type: "REGISTER_COMPANY",
      company: company,
    });

    dispatchUserAction({
      type: "REGISTER_ICONS",
      icons: icons,
    });
    dispatchUserAction({
      type: "SET_PROMOTION",
      pm: pm
    })

    dispatchUserAction({
      type: "REGISTER_BACKGROUNDIMAGE",
      bgImg: bgImg,
      landingImg: landingImg,
      supportMultiLan,
      hasPickUpTime,
      tkOrderAfter,
      startTime,
      endTime,
      currencySymbol,
      disSubCatAtSide,
      skipPaxControl,
      weekDays,
      allowTAafterWH,
      deliveryOrNot,
      deliveryCosts,
      minimumConsumption,
      isForCheckout,
      checkOutRec,
      isOnlinePayMinConsumption,
      onlinePayMinConsumptionVal,
      checkboxs,
      addItemDirect,
      unlimitedScroll,
      catPos,
      itemLayout,
      hasCategoryScreen,
      hasAdvocado,
      includeDineIn,
      tkDiMenuDiff,
      skipCustomerDetail,
      displayNetAmount,
      hasTakeAway,
      hasMember,
      haveToLogin,
      successMessage,
      allowRegister,
      advocadoRedeem,
      skipDIPickUpTime,
      showLogo,
      hidePrice,
      isLanReverse,
      paymentSelectionMessage,
      mustDob,
      payAtCounter,
      hasVoucher,
      sameDayOrder,
      hideOptionalField,
      deliveryCalMethod,
      deliveryDisAmt,
      deliverySpend,
      selectPUTimeFirst,
      showKitchenStatus,
      showPickUpInfo,
      pickUpPoint,
      advocadoStoreCredit,
      recentBought,
      memberRedeem
    });
    dispatchUserAction({
      type: "REGISTER_GREETINGMESSAGE",
      greetingMessage: greetingMessage,
      tkSuccessMessage: tkSuccessMessage,
      successMessage: successMessage
    });
    dispatchUserAction({
      type: "REGISTER_PAX",
      pax: pax,
    });

    dispatchUserAction({
      type: "REGISTER_USERDATA",
      userId: userId,
      orderTime: orderTime,
      token: token
    });
    dispatchUserAction({
      type: "UPDATE_TITLE",
      title: title
    });
    dispatchUserAction({
      type: "REGISTER_ASIAPAY",
      asiaPay: asiaPay,
      hasPayment: hasPayment,
      PaymentTakeawayOnly: PaymentTakeawayOnly,
      paymentPlatform: paymentPlatform,
      isDemo: isDemo,
      haveToPayOnline: haveToPayOnline,
    });
    dispatchUserAction({
      type: "REGISTER_THEME",
      theme: theme
    });
    dispatchUserAction({
      type: "REGISTER_LANINDEX",
      lanIndex: lanIndex
    });
    dispatchUserAction({
      type: "REGISTER_TABLE",
      tableLabel: tableLabel,
      isTakeAway: isTakeAway,
      isMenuView: isMenuView
    });
    dispatchUserAction({
      type: "IS_SCROLLING",
      isScrolling: false
    });
    //clear recommend first
    dispatchUserAction({
      type: "ADD_RECOMMEND",
      recommend: []
    });
    dispatchUserAction({
      type: "ADD_VOUCHER",
      vouchers: vouchers
    })
    if (userState.userId) {
      axios
        .get(
          `/v2/api/selforder/general?userId=${userState.userId}&orderTime=${userState.orderTime}`,
          {
            headers: {
              Authorization: "Bearer " + userState.token,
            },
          }
        )
        .then(function (response) {
          // handle success
          actionContext.setMenu(response.data.stockItems);
          actionContext.registerAds(response.data.ads);
          actionContext.registerCompany(response.data.company);
          actionContext.registerTableLabel(response.data.tableLabel, response.data.isTakeAway, response.data.isMenuView, response.data.isInRoom);
          actionContext.registerTax(response.data.tax);
          actionContext.registerPaymentData(response.data.paymentData);
          actionContext.addPromotion(response.data.promotion);
          actionContext.setContainerItems(response.data.containerItems);
          actionContext.registerUISetup(
            response.data.icons,
            response.data.bgImg,
            response.data.landingImg,
            response.data.greetingMessage,
            response.data.tkSuccessMessage,
            response.data.colorTheme,
            response.data.supportMultiLan,
            response.data.hasPickUpTime,
            response.data.tkOrderAfter,
            response.data.startTime,
            response.data.endTime,
            response.data.currencySymbol,
            response.data.disSubCatAtSide,
            response.data.skipPaxControl,
            response.data.weekDays,
            response.data.allowTAafterWH,
            response.data.deliveryOrNot,
            response.data.deliveryCosts,
            response.data.minimumConsumption,
            response.data.isForCheckout,
            response.data.checkOutRec,
            response.data.isOnlinePayMinConsumption,
            response.data.onlinePayMinConsumptionVal,
            response.data.checkboxs,
            response.data.addItemDirect,
            response.data.unlimitedScroll,
            response.data.catPos,
            response.data.itemLayout,
            response.data.hasCategoryScreen,
            response.data.hasAdvocado,
            response.data.includeDineIn,
            response.data.tkDiMenuDiff,
            response.data.skipCustomerDetail,
            response.data.displayNetAmount,
            response.data.hasTakeAway,
            response.data.hasMember,
            response.data.haveToLogin,
            response.data.successMessage,
            response.data.allowRegister,
            response.data.advocadoRedeem,
            response.data.skipDIPickUpTime,
            response.data.showLogo,
            response.data.hidePrice,
            response.data.isLanReverse,
            response.data.paymentSelectionMessage,
            response.data.mustDob,
            response.data.payAtCounter,
            response.data.hasVoucher,
            response.data.sameDayOrder,
            response.data.hideOptionalField,
            response.data.deliveryCalMethod,
            response.data.deliveryDisAmt, //if deliveryCalMethod is 1, has discount if more than certain
            response.data.deliverySpend,
            response.data.selectPUTimeFirst,
            response.data.showKitchenStatus,
            response.data.showPickUpInfo,
            response.data.pickUpPoint,
            response.data.advocadoStoreCredit,
            response.data.recentBought,
            response.data.memberRedeem
          );
          actionContext.registerOtherUrl(response.data.otherUrls);
          actionContext.registerAsiaPay(
            response.data.asiaPay,
            response.data.hasPayment,
            response.data.paymentPlatform,
            response.data.PaymentTakeawayOnly,
            response.data.isDemo,
            response.data.haveToPayOnline);
          actionContext.registerMenuTitle(
            response.data.menus
          );
          setTheme(themes[response.data.colorTheme]);

        })
        .catch(function (response) {

          // handle error
          if (response.errorCode === 0) {
            //error handler for multiple customer
          }
          if (response.errorCode === 1) {
            //error handler for check out
            localStorage.removeItem("bearerToken");
            localStorage.removeItem("userId");
            localStorage.removeItem("orderTime");
            localStorage.removeItem("pax");
            localStorage.removeItem("ads");
            localStorage.removeItem("tableLabel");
            localStorage.removeItem("company");
            localStorage.removeItem("tax");
            localStorage.removeItem("paymentData");
            localStorage.removeItem("otherUrls");
            localStorage.removeItem("hasPayment");
            localStorage.removeItem("PaymentTakeawayOnly");
            localStorage.removeItem("isTakeAway");
            localStorage.removeItem("isMenuView");
            localStorage.removeItem("TITLE");
            localStorage.removeItem("paymentPlatform");
            localStorage.removeItem("asiaPay");
            localStorage.removeItem("theme");
            localStorage.removeItem("tkSuccessMessage");
            localStorage.removeItem("isDemo")
            localStorage.removeItem("supportMultiLan");
            localStorage.removeItem("hasPickUpTime");
            localStorage.removeItem("tkOrderAfter");
            localStorage.removeItem("startTime");
            localStorage.removeItem("endTime");
            localStorage.removeItem('currencySymbol');
            localStorage.removeItem('disSubCatAtSide');
            localStorage.removeItem('deliveryOrNot');
            localStorage.removeItem('deliveryCosts');
            localStorage.removeItem('minimumConsumption');
            localStorage.removeItem('isForCheckout');
            localStorage.removeItem('checkOutRec');
            localStorage.removeItem('checkboxs');
            localStorage.removeItem('isOnlinePayMinConsumption');
            localStorage.removeItem('onlinePayMinConsumptionVal');
            localStorage.removeItem('haveToPayOnline');
            localStorage.removeItem('addItemDirect');
            localStorage.removeItem('unlimitedScroll');
            localStorage.removeItem('hasCategoryScreen');
            localStorage.removeItem('hasAdvocado');
            localStorage.removeItem("includeDineIn");
            localStorage.removeItem("tkDiMenuDiff");
            localStorage.removeItem("skipCustomerDetail");
            localStorage.removeItem("displayNetAmount");
            localStorage.removeItem("hasTakeAway");
            localStorage.removeItem("hasMember");
            localStorage.removeItem("haveToLogin");
            localStorage.removeItem("allowRegister");
            localStorage.removeItem("advocadoRedeem")
            localStorage.removeItem("skipDIPickUpTime");
            localStorage.removeItem("showLogo")
            localStorage.removeItem("hidePrice");
            localStorage.removeItem("isLanReverse");
            localStorage.removeItem("paymentSelectionMessage");
            localStorage.removeItem('mustDob');
            localStorage.removeItem('payAtCounter');
            localStorage.removeItem('hasVoucher');
            localStorage.removeItem('sameDayOrder');
            localStorage.removeItem('hideOptionalField');
            localStorage.removeItem('deliveryCalMethod');
            localStorage.removeItem('deliveryDisAmt');
            localStorage.removeItem('deliveryPrice');
            localStorage.removeItem('showKitchenStatus');
            localStorage.removeItem('showPickUpInfo');
            localStorage.removeItem('pickUpPoint');
            localStorage.removeItem('advocadoStoreCredit');
            localStorage.removeItem('recentBought');
          }
          if (response.errorCode === 2) {
            //checkout, but try to access within 1 hour
          }
        })
        .then(function () {
          // always executed
        });
    }
  }, [actionContext, userState.orderTime, userState.token, userState.userId]);

  return (
    <React.Fragment>
      <LanguageContext.Provider value={languageContext}>
        <ThemeContext.Provider value={themeContext}>
          <ActionContext.Provider value={actionContext}>
            <UserContext.Provider value={userState}>
              <Router>
                <CustomerReset path="/passwordreset" />
                {userState.token === "" ? (
                  <Checkin path="/checkin/:token" />
                ) : (
                  <React.Fragment>
                    <Checkin path="/checkin/:token/:reserveId" />
                    <Checkin path="/checkin/:token" />
                    <PromotionScreen path="/promo" />
                    <Category path="/category" />
                    <NewMenu path="/menu" />
                    <NewMenu path="/menu/menu/:category" />
                    <NewMenu path="/menu/item/:codeId" />
                    <Checkout path="/checkout" />
                    <PaymentSummary path="/payment" />
                    <Success path="/payment/success" />
                    <Fail path="/payment/fail" />
                    <Cancel path="/payment/cancel" />
                    <OrderFail path="/order/fail" />
                    <CustomerLogin path="/member/login/:token" />
                    <CustomerDetail path="/member/info" />
                  </React.Fragment>
                )}
              </Router>
            </UserContext.Provider>
          </ActionContext.Provider>
        </ThemeContext.Provider>
      </LanguageContext.Provider>
    </React.Fragment>
  );
}

export default App;
