import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThemeContext, UserContext, ActionContext, LanguageContext } from "../contexts/Context";
import MenuItemDisplay3 from "./MenuItemDisplay3";

const RecentBoughtItems = ({
    width,
    menuSelectionFormRef,
    selectedItems,
    theme,
    sidebarWidth,
    headerHeight,
    setSelectedMenu,
    lanIndex,
    addRecentItem
}) => {
    const themeContext = React.useContext(ThemeContext);
    const languageContext = React.useContext(LanguageContext);
    const { recentItem, currencySymbol, isLanReverse } = React.useContext(UserContext);

    useEffect(() => {

    }, [])




    return (
        <RecContainer>
            {recentItem && recentItem.length > 0 &&
                <RecTitle themeContext={themeContext}>
                    <div style={{ flexGrow: 1 }}>
                        {languageContext[lanIndex].recentBought}
                    </div>
                    <div style={{ fontSize: 14, textDecoration: "underline" }} onClick={() => addRecentItem()}>
                        {languageContext[lanIndex].addAll}
                    </div>
                </RecTitle>
            }
            {recentItem && recentItem.length > 0 &&
                <RecWrapper>
                    {recentItem.map((it, i) =>
                        <MenuItemDisplay3
                            key={i}
                            lanIndex={lanIndex}
                            isLanReverse={isLanReverse}
                            item={it}
                            selectedItems={selectedItems}
                            setSelectedMenu={setSelectedMenu}
                            currencySymbol={currencySymbol}
                            menuSelectionFormRef={menuSelectionFormRef}

                        />
                    )}
                </RecWrapper>
            }
        </RecContainer>
    );


};

export default RecentBoughtItems;

const RecTitle = styled.div`
    display: flex;
    font-family: ${(props) => props.themeContext.fontFamily};
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin-left: 16px;
    margin-right: 16px;
`;
const RecContainer = styled.div`
    display:block;
    width: 100%;
    margin-top: 32px;
    padding-top: 16px;
  
`;
const RecWrapper = styled.div`
    display: flex;
    padding-bottom: 16px;
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
    padding-left: 8px;
    padding-right: 8px;
`;