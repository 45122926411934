import axios from "axios";

export const getRecentBought = async (phoneNo, token) => {
    try {
        let response = await axios.post('/v2/api/customer/recentBought',
            { phoneNo: phoneNo },
            { headers: { Authorization: "Bearer " + token } }
        )

        return response.data;
    } catch (e) {
        console.log(e);
    }
}
