import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext } from "../../contexts/Context";
import { IoMdClose } from "react-icons/io";
import moment from "moment";
const Modal = ({
  show,
  setShowTime,
  changeBookingTime,
  lanIndex,
  deliveryType
}) => {
  const displayShow = show ? "block" : "none";
  const themeContext = React.useContext(ThemeContext);
  const languageContext = React.useContext(LanguageContext);
  const [date, setDate] = useState("");
  const [timeSlot, setTimeSlot] = useState("");
  const { startTime, endTime, weekDays, sameDayOrder, tkOrderAfter, company } = React.useContext(UserContext);

  const timeSlotArr = [
    '00:00 - 00:15', '00:15 - 00:30', '00:30 - 00:45', '00:45 - 01:00', '01:00 - 01:15', '01:15 - 01:30', '01:30 - 01:45', '01:45 - 02:00',
    '02:00 - 02:15', '02:15 - 02:30', '02:30 - 02:45', '02:45 - 03:00', '03:00 - 03:15', '03:15 - 03:30', '03:30 - 03:45', '03:45 - 04:00',
    '04:00 - 04:15', '04:15 - 04:30', '04:30 - 04:45', '04:45 - 05:00', '05:00 - 05:15', '05:15 - 05:30', '05:30 - 05:45', '05:45 - 06:00',
    '06:00 - 06:15', '06:15 - 06:30', '06:30 - 06:45', '06:45 - 07:00', '07:00 - 07:15', '07:15 - 07:30', '07:30 - 07:45', '07:45 - 08:00',
    '08:00 - 08:15', '08:15 - 08:30', '08:30 - 08:45', '08:45 - 09:00', '09:00 - 09:15', '09:15 - 09:30', '09:30 - 09:45', '09:45 - 10:00',
    '10:00 - 10:15', '10:15 - 10:30', '10:30 - 10:45', '10:45 - 11:00', '11:00 - 11:15', '11:15 - 11:30', '11:30 - 11:45', '11:45 - 12:00',
    '12:00 - 12:15', '12:15 - 12:30', '12:30 - 12:45', '12:45 - 13:00', '13:00 - 13:15', '13:15 - 13:30', '13:30 - 13:45', '13:45 - 14:00',
    '14:00 - 14:15', '14:15 - 14:30', '14:30 - 14:45', '14:45 - 15:00', '15:00 - 15:15', '15:15 - 15:30', '15:30 - 15:45', '15:45 - 16:00',
    '16:00 - 16:15', '16:15 - 16:30', '16:30 - 16:45', '16:45 - 17:00', '17:00 - 17:15', '17:15 - 17:30', '17:30 - 17:45', '17:45 - 18:00',
    '18:00 - 18:15', '18:15 - 18:30', '18:30 - 18:45', '18:45 - 19:00', '19:00 - 19:15', '19:15 - 19:30', '19:30 - 19:45', '19:45 - 20:00',
    '20:00 - 20:15', '20:15 - 20:30', '20:30 - 20:45', '20:45 - 21:00', '21:00 - 21:15', '21:15 - 21:30', '21:30 - 21:45', '21:45 - 22:00',
    '22:00 - 22:15', '22:15 - 22:30', '22:30 - 22:45', '22:45 - 23:00', '23:00 - 23:15', '23:15 - 23:30', '23:30 - 23:45', '23:45 - 23:59'
  ];

  if (company._id == "677ae529d90797dc89c7091a") {
    timeSlotArr.splice(57, 11);
  }
  const formatDate = (date) => {
    return {
      data: moment(date).format("YYYY-MM-DD"),
      day: date.getDate(),
      week: date.getDay()
    }
  };
  const isCurrentTime = (item) => {
    if (date == moment().format("YYYY-MM-DD")) {
      let timeS = item.split(' - ')[0];
      let timeE = item.split(' - ')[1];
      let timeStampStart = moment().set("hour", parseInt(timeS.split(":")[0])).set("minute", parseInt(timeS.split(":")[1]));
      let timeStampEnd = moment().set("hour", parseInt(timeE.split(":")[0])).set("minute", parseInt(timeE.split(":")[1]));
      let now = moment();
      if (now.isSameOrAfter(timeStampStart) && now.isSameOrBefore(timeStampEnd)) {
        return true;
      }
    }

    return false;
  }
  const getWeekDay = (dateString) => {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();

    let days;
    if (company._id == "677ae529d90797dc89c7091a") {
      days = 3;
    } else
      days = new Date(year, month + 1, 0).getDate();
    return Array.from({ length: days }, () => 0).map((val, index) => {

      return formatDate(new Date(moment(dateString).add(index, "day").valueOf()));
    });
  }
  const arrChange = (arr) => {
    if (arr[0].week != 0) {
      arr = Array(arr[0].week).fill('').concat(arr);
    }
    if (arr[arr.length - 1].week != 6) {
      arr = arr.concat(Array(6 - arr[arr.length - 1].week).fill(''));
    }
    let newArr = [];
    while (arr.length > 0) {
      newArr.push(arr.splice(0, 7));
    }
    return newArr;
  }

  let myDate = new Date()
  let today = myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
  let currentTimeStamp = moment(0).set("hour", moment().add(tkOrderAfter ? tkOrderAfter : 0, "minute").get("hour")).set("minute", moment().add(tkOrderAfter ? tkOrderAfter : 0, "minute").get("minute")).valueOf();
  let dateArr = getWeekDay(moment());
  let weekDayTxt = arrChange(dateArr);

  let timeSlotArrNew = [];
  let weekTxt = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  timeSlotArr.map((item) => {
    let timeS = item.split(' - ')[0];
    let timeE = item.split(' - ')[1];
    let sartt = startTime;
    let endtt = endTime;

    if (date == moment().format("YYYY-MM-DD")) {
      let now = moment().add(tkOrderAfter ? tkOrderAfter : 0, "minute");
      if (moment(startTime).get("hour") <= now.get("hour"))
        sartt = now;
    }
    

    if (company._id == "677ae529d90797dc89c7091a") {
      if (moment(date, "YYYY-MM-DD", true).isoWeekday() <= 3) {
        endtt = new Date(2025, 0, 1, 19, 30).getTime();
      }
    }

    let st = moment().set("hour", moment(sartt).get("hour")).set("minute", moment(sartt).get("minute"));
    let et = moment().set("hour", moment(endtt).get("hour")).set("minute", moment(endtt).get("minute"));



    let timeStampStart = moment().set("hour", parseInt(timeS.split(":")[0])).set("minute", parseInt(timeS.split(":")[1]));
    let timeStampEnd = moment().set("hour", parseInt(timeE.split(":")[0])).set("minute", parseInt(timeE.split(":")[1]));
    if ((deliveryType == 0 || deliveryType == 1 || timeStampStart.isSameOrAfter(st)) && timeStampEnd.isSameOrAfter(st) &&
      timeStampStart.isSameOrBefore(et) && timeStampEnd.isSameOrBefore(et)
    ) {
      timeSlotArrNew.push(item)
    }
  })

  useEffect(() => {
    let tdy = moment().get("day");
    let tdyTxt = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][tdy];
    if (!weekDays || weekDays.indexOf(tdyTxt) < 0 || weekDays.length == 0) return;
    setDate(moment().format("YYYY-MM-DD"));
  }, []);

  const modalContent = () => {

    return (
      <React.Fragment key={0}>
        <ModelWrapperLeft>
          <ModalBox>
            {!sameDayOrder ?
              weekDayTxt.map((i, idx1) => (
                <ModelWeeks key={idx1}>
                  {
                    i.filter(j => j !== "").map((j, idx2) => (
                      <ModelWeekWrap key={idx1 + '-' + idx2}
                        isSelect={date == j.data}
                        color={'black'}
                        onClick={() => {
                          if (j == '') return;
                          console.log(j.data);
                          if (weekDays && weekDays.indexOf(moment(j.data, "YYYY-MM-DD", true).format('dddd')) == -1) return;
                          setDate(j.data);
                          setTimeSlot('')
                        }}
                      >
                        <div>
                          <ModelWeekDayTxt themeContext={themeContext}
                            opacity={weekDays && weekDays.indexOf(moment(j.data, "YYYY-MM-DD", true).format('dddd')) == -1 ? 0.3 : 1}
                            color={'black'}
                            backgroundColor={date == j.data ? '#fff' : ''}
                          >{moment(j.data, "YYYY-MM-DD", true).format("DD MMM")}</ModelWeekDayTxt>
                          <ModelWeekTxt
                            opacity={weekDays && weekDays.indexOf(moment(j.data, "YYYY-MM-DD", true).format('dddd')) == -1 ? 0.5 : 1}
                            color={'black'}
                          >
                            {moment(j.data, "YYYY-MM-DD", true).format("ddd")}
                          </ModelWeekTxt>
                        </div>
                      </ModelWeekWrap>
                    ))
                  }
                </ModelWeeks>
              ))
              :
              <ModelWeeks >
                <ModelWeekWrap
                  isSelect={date == moment().format("YYYY-MM-DD")}
                  color={'black'}
                  onClick={() => {
                    if (weekDays && weekDays.indexOf(moment().format('dddd')) == -1) return;
                    setDate(moment().format("YYYY-MM-DD"));
                    setTimeSlot('')
                  }}
                >
                  <div>
                    <ModelWeekDayTxt themeContext={themeContext}
                      opacity={weekDays && weekDays.indexOf(moment().format('dddd')) == -1 ? 0.3 : 1}
                      color={'black'}
                      backgroundColor={date == moment().format("YYYY-MM-DD") ? '#fff' : ''}
                    >{moment().format("DD MMM")}</ModelWeekDayTxt>
                    <ModelWeekTxt
                      opacity={weekDays && weekDays.indexOf(moment().format('dddd')) == -1 ? 0.5 : 1}
                      color={'black'}
                    >
                      {moment().format("ddd")}
                    </ModelWeekTxt>
                  </div>
                </ModelWeekWrap>
              </ModelWeeks>
            }
          </ModalBox>
        </ModelWrapperLeft>
        <ModelWrapperRight>
          <ModalTimeBox>
            {timeSlotArrNew.map((item, index) => (
              <ModelDate key={index}
                color={today == date && Date.parse('1970-01-01 ' + item.split(' - ')[1]) < currentTimeStamp ? '#bdbdbd' : timeSlot == item ? '#Cf000d' : 'black'}
                onClick={() => {
                  if (date == '') return;
                  if (today == date && Date.parse('1970-01-01 ' + item.split(' - ')[1]) < currentTimeStamp) return
                  setTimeSlot(item);
                  setShowTime(false);
                  changeBookingTime(date + ' ' + item);
                }}>{isCurrentTime(item) ? "Now" : item}</ModelDate>
            ))}
          </ModalTimeBox>
        </ModelWrapperRight>
      </React.Fragment>
    );
  };

  return (
    <ModalContainer display={displayShow}>
      <ModalMain themeContext={themeContext}>
        <ModalTitle themeContext={themeContext}>
          <ModalText
            fontSize={16}
            fontWeight={500}
            color={themeContext.cartFont}
            margin={10}
          >
            {languageContext[lanIndex].bookingTime}
          </ModalText>
          <DeleteButton
            onClick={() => {
              setShowTime(false);
            }}
          >
            <IoMdClose size={24} />
          </DeleteButton>
        </ModalTitle>

        <ModalContent themeContext={themeContext}>
          {modalContent()}
        </ModalContent>
      </ModalMain>
    </ModalContainer>
  );
};

export default Modal;

const ModalContainer = styled.div`
  display: ${(props) => props.display};
  position: fixed;
  place-content: center;
  top: 0;

  width: 100vw;
  max-width: 550px;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
`;

const ModalMain = styled.div`
  border-radius: 8px 8px 0 0;
  place-content: center;
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  bottom: 0;
  width: 100%;
  font-family: ${(props) => props.themeContext.fontFamily};
  background-color: ${(props) => props.themeContext.backgroundColor};
  color: black;
  z-index: 10;
  height: 100vh;
  animation: booking .2s;
  animation-timing-function: ease-out;
  @keyframes booking {
    0%  {
      transform: translateY(448px);
    }
    
    100%  {
      transform: translateY(0px);
    }
    
  }
`;

const ModalTitle = styled.div`
  background: ${(props) => props.themeContext.fontPrimary};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-top: 10px;
  align-self: center;
  justify-content: center;
  text-align: left;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  height: 28px;
  align-items: center;
`;
const ModalContent = styled.div`
  background-color: ${(props) => props.themeContext.backgroundColor};
  color: ${(props) => props.themeContext.fontPrimary};
  display: flex;
  overflow: scroll;
  text-align: ${(props) => (props.error ? "left" : "center")};
  align-items: start;
  margin-top: 0px;
`;
const ModalText = styled.span`
  align-self: center;
  justify-content: center;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  color: ${(props) => (props.color ? props.color : "black")};
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  padding-left: ${(props) => (props.margin ? props.margin : 0)}px;
  padding-right: ${(props) => (props.margin ? props.margin : 0)}px;
`;

const ModelWrapperLeft = styled.div`
  height: calc(100vh - 48px);
  flex-grow: 1;
  font-size: 16px;
  background: #f5f4f4;
`;

const ModelWrapperRight = styled.div`
  height: calc(100vh - 48px);
  flex-grow: 3;
  font-size: 16px;
  overflow-y: auto;
  background: #fff;
 
`;
const ModelDate = styled.div`
  margin: 8px 16px;
  border: 1px solid black;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
 
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
`


const DeleteButton = styled.div`
  display: grid;
  align-self: center;
  place-content: center;
  color: white;
  background-color: #cf000d;
  width: 28px;
  height: 28px;
  margin-bottom: 10px;
  margin-left: -10px;
  border-radius: 15px;
  box-shadow: 2px 2px 3px rgb(0 0 0 / 30%);
  text-align: center;
  margin-right: 10px;
  margin-top: 5px;
`;

const ModelWeeks = styled.div`
`
const ModelWeekWrap = styled.div`
  height: 68px;
  display:flex;
  align-items: center;
  justify-content: center;
  margin: 4px 4px;
  border-radius: 8px;
  border:${(props) => props.isSelect ? '1px solid ' + props.color : 'none'}
`;
const ModelWeekTxt = styled.div`
  line-height: 14px;
  font-size:16px;
  opacity: ${(props) => props.opacity};
  color: ${(props) => props.color};
`
const ModelWeekDayTxt = styled.span`
  display: block;
  color: ${(props) => props.color};
  border-radius: 12px;
  opacity: ${(props) => props.opacity};
  font-weight: 900;
`
const ModalBox = styled.div`
  overflow: auto;
  height: 100%;
`
const ModalTimeBox = styled.div`
  text-align: center;
  padding-bottom: 100px;
`