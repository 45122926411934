import React, { useRef, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext, ActionContext } from "../contexts/Context";
import useWindowDimensions from "../hooks/useWindowDimensions";
import MenuInfoContainer from "./MenuInfoContainer";
import Modal from './optioncomponents/Modal';
import promo from '../newcomponents/customerComponents/Promotion';
const EditItemModal = ({
    show,
    setShow,
    item,
    index,
    cart
}) => {
    //State
    const [displayShow, setDisplay] = useState(show ? "grid" : "none");
    const themeContext = React.useContext(ThemeContext);
    const languageContext = React.useContext(LanguageContext);
    const { theme, lanIndex, pm, isLanReverse } = React.useContext(UserContext);
    const { width } = useWindowDimensions();
    const { removeFromCart } = React.useContext(ActionContext);
    const [sh, setSh] = useState(false);
    const [error, setError] = useState([]);
    const itemRef = useRef(null);

    const handleEdit = () => {
        if (!checkIfSelected()) return;
        cart.splice(index, 1, JSON.parse(JSON.stringify(itemRef.current)));

        if (pm && pm.length > 0) {
            cart = promo.clearPromotion(cart);
            cart = promo.applyPromotion(pm, cart);
        }
        cart = JSON.parse(JSON.stringify(cart));

        localStorage.setItem("order", JSON.stringify(cart));
        removeFromCart(cart, index);
        itemRef.current = null;
        setShow(false);
    }

    useEffect(() => {
        if (item != null) {
            setDisplay(show ? "grid" : "none");
            itemRef.current = JSON.parse(JSON.stringify(item));
        }
    }, [item, show]);

    const checkIfSelected = () => {
        const skipOption = ["No Dressing Please!", "No Crunches"];
        let errorMessages = [];
        let optionArray = itemRef.current.optionArray;

        let alertSubject = `${languageContext[lanIndex].setMeal}: `;
        let quantityCheck = itemRef.current.qty;

        if (itemRef.current.isSetMeal === true) {
            optionArray.forEach((element) => {
                if (element.optionActive === true && element.optionRequired && element.options.length > 0) {
                    let selected = 0;
                    let hasNoOption = false;
                    element.options.forEach((option) => {
                        if (option.active) {
                            selected += (option.qty ? option.qty : 1);
                            if (skipOption.indexOf(option.title) > -1) hasNoOption = true;
                        }
                    });

                    let foundActiveOptionArraySelection = element.options.find((option) => option.active === true);

                    if (!foundActiveOptionArraySelection || (element.isMultiple && selected < element.minQty && !hasNoOption)) {
                        errorMessages.push({
                            errorElement: element.optionTitle,
                            errorMessage: (
                                <>
                                    <b style={{ color: "#CF000D" }}>
                                        {alertSubject + (lanIndex == 0 ? element.optionTitle : element.optionTitle2)}
                                    </b>&nbsp;
                                    <b>{languageContext[lanIndex].required}</b>, {languageContext[lanIndex].pleasePick}.
                                </>
                            ),
                        });
                    } else {
                        // console.log(foundActiveOptionArraySelection.optionArray);
                        //nested Option
                        foundActiveOptionArraySelection.optionArray &&
                            foundActiveOptionArraySelection.optionArray.forEach((element2) => {
                                if (element2.optionActive === true && element2.optionRequired) {
                                    let selected2 = 0;
                                    element2.options.forEach((option) => { if (option.active) selected2 += (option.qty ? option.qty : 1); });
                                    let setMealDepthOptionFound = element2.options.find((option) => option.active === true);
                                    if ((element2.isMultiple && selected2 < element2.minQty) || !setMealDepthOptionFound) {
                                        errorMessages.push({
                                            errorElement: element2.optionTitle,
                                            errorMessage: (
                                                <>
                                                    <b style={{ color: "#CF000D" }}>
                                                        {(lanIndex === 0 ? element2.optionTitle : element2.optionTitle2)}
                                                    </b>
                                                    {" of "}
                                                    <b style={{ color: "#CF000D" }}>
                                                        {alertSubject + (lanIndex === 0 ? element.optionTitle : element.optionTitle2)}
                                                    </b>&nbsp;
                                                    <b>{languageContext[lanIndex].required}</b>, {languageContext[lanIndex].pleasePick}.
                                                </>
                                            ),
                                        });
                                    }
                                }
                            });
                    }
                } else if (element.optionActive === true && element.options.length > 0) {
                    //even option is not compulsory, but nested may be compulsory
                    let foundActiveOptionArraySelection = element.options.find((option) => option.active === true);
                    foundActiveOptionArraySelection && foundActiveOptionArraySelection.optionArray &&
                        foundActiveOptionArraySelection.optionArray.forEach((element2) => {
                            if (element2.optionActive === true && element2.optionRequired) {
                                let selected2 = 0;
                                element2.options.forEach((option) => { if (option.active) selected2 += (option.qty ? option.qty : 1); });
                                let setMealDepthOptionFound = element2.options.find((option) => option.active === true);
                                if ((element2.isMultiple && selected2 < element2.minQty) || !setMealDepthOptionFound) {
                                    errorMessages.push({
                                        errorElement: element2.optionTitle,
                                        errorMessage: (
                                            <>
                                                <b style={{ color: "#CF000D" }}>
                                                    {((lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? element2.optionTitle : element2.optionTitle2)}
                                                </b>
                                                {" of "}
                                                <b style={{ color: "#CF000D" }}>
                                                    {alertSubject + ((lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? element.optionTitle : element.optionTitle2)}
                                                </b>&nbsp;
                                                <b>{languageContext[lanIndex].required}</b>, {languageContext[lanIndex].pleasePick}.
                                            </>
                                        ),
                                    });
                                }
                            }
                        });
                }
            });
        } else {
            optionArray.forEach((element) => {
                if (element.optionActive === true && element.optionRequired) {
                    let checkActive = false;
                    let selected = 0;
                    let hasNoOption = false;
                    element.options.forEach((option) => {
                        if (option.active === true) {
                            checkActive = true;
                            if (skipOption.indexOf(option.title) > -1) hasNoOption = true;
                            selected += (option.qty ? option.qty : 1);
                        }
                    });

                    if ((checkActive === false && !element.isMultiple) || (element.isMultiple && selected < element.minQty && !hasNoOption)) {
                        errorMessages.push({
                            errorElement: element.optionTitle,
                            errorMessage: (
                                <>
                                    <b style={{ color: "#CF000D" }}>{lanIndex === 0 ? element.optionTitle : element.optionTitle2}</b>
                                    &nbsp;
                                    <b>{languageContext[lanIndex].required}</b>, {languageContext[lanIndex].pleasePick}.
                                </>
                            ),
                        });
                    }
                }
            });
        }

        if (quantityCheck < 1) {
            errorMessages.push({
                errorElement: "Quantity",
                errorMessage: (
                    <>
                        <b style={{ color: "#CF000D" }}>Quantity</b> cannot be less than{" "}
                        <b>1</b> please re-adjust.
                    </>
                ),
            });
        }

        if (errorMessages.length > 0) {
            console.log(errorMessages);
            setError(errorMessages);
            setSh(true);
            return false;
        }
        return true;
    };

    return (
        <React.Fragment>
            <ModalContainer display={displayShow}>
                <ModalMain id="menu_scroll" themeContext={themeContext}>
                    {(item != null && itemRef.current != null) &&
                        <MenuInfoContainer
                            width={width}
                            menuSelectionFormRef={itemRef}
                            selectedMenu={item}
                            theme={theme}
                            isEdit={true}
                            setShow={setShow}
                            cp={1}
                        />
                    }
                    <AddCartButtonContainer>
                        <AddCartButton
                            width={width}
                            themeContext={themeContext}
                            onClick={() => {
                                handleEdit()
                            }}
                        >
                            {languageContext[lanIndex].edit}
                        </AddCartButton>
                    </AddCartButtonContainer>
                </ModalMain>
            </ModalContainer>
            <Modal show={sh} setShow={setSh} error={error} />
        </React.Fragment>
    );
};

export default EditItemModal;

const AddCartButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 25;
  width: 100%;
  max-width: 500px;
`;
const ModalContainer = styled.div`
    display: ${(props) => props.display};
    position: fixed;
    background: #fff;
    top: 0;

    width: 100vw;
    max-width:550px;
    height: 100vh;
    z-index: 10;
    max-width: 550px;
  `;

const ModalMain = styled.div`
    position: relative;
    font-family: ${(props) => props.themeContext.fontFamily};
    background: #fff;
    z-index: 10;
    width: 100vw;
    max-width: 550px;
    height: calc(100vh);
    overflow: auto;
  `;
const AddCartButton = styled.div`
  display: flex;
 
  text-align: center;
  color: ${(props) => props.themeContext.cartFont};
  font-family: ${(props) => props.themeContext.fontFamily};
  font-size: 16px;
  background-color: ${(props) => props.themeContext.cartColor2};
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  height: 40px;
  right: 20px;
  left: 20px;
  /*width: 50%; */
  bottom: 10px;
  box-shadow: 0px 0px 13px rgb(0 0 0 / 90%);
  z-index: 99;
  border-radius: 6px;
  width: 85%;
  max-width: 400px;
`;