import React from "react";
import styled from "@emotion/styled";
import MenuSelection from "./MenuItemSelectionGrid.js";
import MenuUnlimitedScroll from './MenuItemUnlimitedScroll';
// import MenuSelectionBar from "./MenuItemSelectionBar.js";
import MenuInfoContainer from "./MenuInfoContainer.js";
import RecentBoughtItems from "./RecentBoughtItems.js";
import { ThemeContext, UserContext, LanguageContext } from "../contexts/Context";
import "../styles/styles.css";
import { navigate } from "@reach/router";

const MenuContainer = ({
  height,
  width,
  headerHeight,
  sidebarWidth,
  menuSelectionFormRef,
  selectedCategory,
  selectedMenu,
  setSelectedMenu,
  setSelectedCategory,
  theme,
  cart,
  addItemDirect,
  removeFromCart,
  unlimitedScroll,
  menuList,
  lanIndex,
  isLanReverse,
  isScrolling,
  lastPos,
  setLastPos,
  catPos,
  addRecItemToCart,
  addRecentItem,
  recentBought,
  recentItem

}) => {
  const themeContext = React.useContext(ThemeContext);
  const userContext = React.useContext(UserContext);
  const languageContext = React.useContext(LanguageContext);

  let selectedItems = {};
  for (let ct of cart) {
    if (selectedItems[ct.codeId]) {
      selectedItems[ct.codeId] += ct.qty;
    } else {
      selectedItems[ct.codeId] = ct.qty;
    }
  }
  const closeMenu = () => {
    setSelectedMenu(null);
    if (selectedCategory != null) {
      let cat = selectedCategory.category;
      if (cat != null) {
        cat = cat.replaceAll("/", "").replaceAll("%", "");
        if (!unlimitedScroll)
          navigate('/menu/menu/' + cat);
        else
          navigate('/menu');
      }
    }
  }


  return (
    <RootContainer id="menu_scroll" themeContext={themeContext} headerHeight={headerHeight} sidebarWidth={sidebarWidth} width={width} height={height}>
      {(!selectedMenu && recentBought && recentItem && recentItem.length > 0 && unlimitedScroll) &&
        <React.Fragment>
          <RecentBoughtItems
            width={width}
            menuSelectionFormRef={menuSelectionFormRef}
            selectedItems={selectedItems}
            theme={theme}
            sidebarWidth={sidebarWidth}
            headerHeight={headerHeight}
            lanIndex={lanIndex}
            isLanReverse={isLanReverse}
            addRecentItem={addRecentItem}
            setSelectedMenu={setSelectedMenu}
          />
        </React.Fragment>
      }

      {(selectedCategory || unlimitedScroll) ? (
        <React.Fragment>
          {(selectedMenu && (!addItemDirect || (addItemDirect && selectedMenu.optionArray.length > 0))) ? (
            //display product detail
            <MenuInfoContainer
              width={width}
              menuSelectionFormRef={menuSelectionFormRef}
              selectedMenu={selectedMenu}
              theme={theme}
              isEdit={catPos == 1}
              setShow={closeMenu}
              cp={catPos}
              addRecItemToCart={addRecItemToCart}
            />
          ) : (unlimitedScroll ?
            <MenuUnlimitedScroll
              width={width}
              height={height}
              sidebarWidth={sidebarWidth}
              headerHeight={headerHeight}
              theme={theme}
              selectedItems={selectedItems}
              addItemDirect={addItemDirect}
              cart={cart}

              menuList={menuList}
              selectedCategory={selectedCategory}
              setSelectedMenu={setSelectedMenu}
              setSelectedCategory={setSelectedCategory}
              removeFromCart={removeFromCart}
              lanIndex={lanIndex}
              isLanReverse={isLanReverse}
              isScrolling={isScrolling}
              lastPos={lastPos}
              setLastPos={setLastPos}
              cp={catPos}
              menuSelectionFormRef={menuSelectionFormRef}
            />
            :
            <MenuSelection //display list of item
              width={width}
              height={height}
              theme={theme}
              sidebarWidth={sidebarWidth}
              headerHeight={headerHeight}
              selectedItems={selectedItems}
              addItemDirect={addItemDirect}
              cart={cart}
              selectedCategory={selectedCategory}
              setSelectedMenu={setSelectedMenu}
              setSelectedCategory={setSelectedCategory}
              removeFromCart={removeFromCart}
              menuSelectionFormRef={menuSelectionFormRef}
              cp={catPos}

            />
          )
          }
        </React.Fragment>
      ) : (
        <PromptContainer
          themeContext={themeContext}
          userContext={userContext}
          height={height}
        >
          {userContext.bgImg === ""
            ? languageContext[userContext.lanIndex].bgTxt
            : ""}
        </PromptContainer>
      )}
    </RootContainer>
  );
};
export default React.memo(MenuContainer);

const RootContainer = styled.div`
  position: relative;
  text-align: center;
  margin-right: 1px;
  background-color: ${(props) => props.themeContext.backgroundColor};
  //Height values
  height: calc(100vh - ${(props) => props.headerHeight}px);
  width: ${(props) => props.width - props.sidebarWidth}px;
  margin-top: ${(props) => props.headerHeight}px;
  margin-left: ${(props) => props.sidebarWidth}px;
  /*z-index: 4;*/
  max-width: ${(props) => 550 - props.sidebarWidth}px;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  overflow-y: auto;
`;

const PromptContainer = styled.div`
  display: grid;
  color: darkgrey;
  height: calc(100vh - 35px);
  width: 100%;
  align-items: center;
  background-image: url(${(props) => "https://ireachfnb.com/" + props.userContext.bgImg});
  /*background-image: url(${(props) => "https://newdemo.chachingfnb.com/" + props.userContext.bgImg});*/
  background-position:  center;
  background-size: cover;
  
`;
