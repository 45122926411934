import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { ThemeContext, UserContext, ActionContext, LanguageContext } from "../contexts/Context";
import { IoIosAddCircleOutline, IoIosRemoveCircleOutline } from "react-icons/io";


const MenuItemDisplay3 = ({
    item,
    lanIndex,
    isLanReverse,
    currencySymbol,
    selectedItems,
    setSelectedMenu,
    menuSelectionFormRef
}) => {

    const themeContext = React.useContext(ThemeContext);

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        menuSelectionFormRef.current = JSON.parse(JSON.stringify(item));
        setSelectedMenu(JSON.parse(JSON.stringify(item)));
    };

    return (
        <ItemWrapper onClick={handleClick} themeContext={themeContext}>
            <ItemDisplay
                image={item.image}
                themeContext={themeContext}
            ></ItemDisplay>
            <OptionsDisplay themeContext={themeContext} >
                <MenuInfoHeaderContainer>
                    <TitleContainer
                        themeContext={themeContext}
                    >{`${(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? item.title : item.title2 ? item.title2 : item.title}`}</TitleContainer>

                </MenuInfoHeaderContainer>
                <PriceField>
                    {currencySymbol}&nbsp;{item.price}
                </PriceField>
                {/* Quantity Component */}
                {selectedItems[item.codeId] && selectedItems[item.codeId] > 0 &&
                    <ItemQty themeContext={themeContext}>x{selectedItems[item.codeId]}</ItemQty>
                }

            </OptionsDisplay>
        </ItemWrapper>
    );


};

export default MenuItemDisplay3;

const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 8px;
  padding: 8px;
  border-radius: 8px;
  min-width: 60%;
  width: 60%;
  color: ${(props) => props.themeContext.fontSecondary};
  background-color:${(props) =>  props.themeContext.optionHeaderBackgroundColor};
  box-shadow: 1px 1px 2px rgba(0,0,0,0.7);
`;

const ItemQty = styled.div`
  position: absolute;
  font-size: 12px;
  background-color: ${(props) => props.themeContext.fontSecondary};
  color: ${(props) => props.themeContext.primaryColor}; 
  bottom: 16px;
  right: 16px;
  border-radius: 13px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.65); 
`;

const ItemDisplay = styled.div`
  display: block;
  text-align: center;
  justify-content: center;
  align-items: center;
  height:  60px;
  width: 60px;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const OptionsDisplay = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 6px;

  font-family: ${(props) => props.themeContext.fontFamily};
  text-align: left;
  font-size: 14px;
  width: calc(100% - 60px);
`;

const MenuInfoHeaderContainer = styled.div`
  display: -webkit-box;
  flex-grow:1;
  -webkit-line-clamp: 2;
  word-wrap:break-word;
  -webkit-box-orient: vertical;
  overflow:hidden;
`;

const TitleContainer = styled.div`
  display: block;
  font-weight: 600;

`;

const PriceField = styled.div`
  display: block;
`;

const QtyContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 30px;
  color: ${(props => props.themeContext.primaryColor)};
`;

const QtyButton = styled.div`
  display:flex;
`;
const QuantityContainer = styled.div`
  display:block;
  font-size: 20px;
  padding: 0px 8px;
  
`;